import { showMessage } from '../utils/message'
import { commandHints } from '../config/commandHints'
import mapManager from '../utils/mapManager'

export default function helpCommand(params) {
  const { showInfoPanel, infoList } = mapManager.getPanelControls();
  
  // 显示所有命令的简略信息
  const commandList = Object.values(commandHints).map(hint => ({
    command: hint.command + (hint.aliases.length ? ` (${hint.aliases.join(', ')})` : ''),
    description: hint.description,
    usage: hint.usage
  }));

  infoList.value = {
    type: 'list',
    data: commandList.map(item => ({
      content: `${item.command.padEnd(30)} - ${item.description}`,
      style: { color: 'black' }
    })),
    title: '可用命令列表'
  };

  showInfoPanel.value = true;
  showMessage('命令帮助已显示', 'success');
} 