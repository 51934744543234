<template>
  <div class="app-container">
    <!-- 加载遮罩和进度条 -->
    <div v-show="!initComplete" class="loading-overlay">
      <div class="loading-content">
        <el-progress 
          :percentage="loadingProgress" 
          :stroke-width="20"
          :show-text="false"
        />
        <div class="loading-text">{{ loadingMessage }}</div>
      </div>
    </div>
    <div class="user-info" v-if="userRole && userRole !== 'guest'">
        <el-tooltip
          :content="`权限: ${userRole}`"
          placement="right"
          effect="light"
        >
          <div class="username">
            <el-icon><User /></el-icon>
            <span>{{ username }}</span>
          </div>
      </el-tooltip>
    </div>
    <div class="map-container">
      <baidu-map ref="mapRef" class="bm-view" :zoom="14" :center="mapCenter" :scroll-wheel-zoom=true :map-type="mapType" :hasAnimation="false" :mapStyle="mapStyle" 
       @ready="onMapReady" @zoomend="syncCenterAndZoom">
        <!-- 常规控件 -->
        <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
        <bm-navigation v-if="showNavigation"  anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :type="BMAP_NAVIGATION_CONTROL_SMALL" :offset="{width: 42, height: 60}"></bm-navigation>
        <!-- <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type> -->
        <bm-copyright anchor="BMAP_ANCHOR_BOTTOM_LEFT"
          :copyright="[{ id: 1, content: 'Copyright 2024 <a>YZ</a>'}]" :offset="{width: 460, height: 3}">
        </bm-copyright>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>

        <!-- 以下是用原生api添加的控件 -->
          <!-- 添加地图类型切换按钮 -->
        <div class="map-type-control">
          <el-button-group>
            <el-button 
              :type="mapType === 'BMAP_NORMAL_MAP' ? 'primary' : 'default'"
              size="small"
              @click="setMapType('BMAP_NORMAL_MAP')"
            >
              普通
            </el-button>
            <el-button 
              :type="mapType === 'BMAP_SATELLITE_MAP' ? 'primary' : 'default'"
              size="small"
              @click="setMapType('BMAP_SATELLITE_MAP')"
            >
              卫星
            </el-button>
          </el-button-group>
        </div>
        <!-- 右键菜单，user/manager可见，只用于绘制图形 -->
        <bm-context-menu v-if="hasRightClickMenuPermission">
            <bm-context-menu-item :callback="(e) => handleDrawCommand('marker', e)" text="绘制Marker"></bm-context-menu-item>
            <bm-context-menu-item :callback="() => handleDrawCommand('polyline')" text="绘制折线"></bm-context-menu-item>        
            <bm-context-menu-item :callback="() => handleDrawCommand('polygon')" text="绘制多边形"></bm-context-menu-item>
        </bm-context-menu>
        <!-- 渲染地图上的元素 -->
        <div v-for="marker in currentElement.marker" :key="marker.pid">
          <bm-marker :position="marker.points[0]" v-bind="getMarkerStyle(marker.style)" :massClear="false" :icon="getMarkerStyle(marker.style).iconURL? {url: '../icons/'+getMarkerStyle(marker.style).iconURL, size: {width: getMarkerStyle(marker.style).iconSize, height:getMarkerStyle(marker.style).iconSize}}:''" @click="showInfoWindow(marker)">
            <bm-label v-if="marker.showLabel" :content="marker.title" :offset="{ width: -10, height: 12 }" :labelStyle="getLabelStyle(marker.style)" @dblclick="handleEditPanelCommand(marker)" @click="handleViewport(marker.points)" title="单击聚焦 双击编辑"/>
          </bm-marker>
        </div>
        <div v-for="polyline in currentElement.polyline" :key="polyline.pid">
          <bm-polyline :path="polyline.points" v-bind="getPolylineStyle(polyline.style)" :massClear="false" @click="showInfoWindow(polyline)"></bm-polyline>
          <bm-label v-if="polyline.showLabel" :content="polyline.title" :position="polyline.labelPosition || calculatePointsCenter([polyline.points[0],polyline.points[1]])" :massClear="false" :offset="{ width: 0, height: 0 }"  :labelStyle="getLabelStyle(polyline.style)" @dblclick="handleEditPanelCommand(polyline)" @click="handleViewport(polyline.points)" title="单击聚焦 双击编辑"/>
        </div>
        <div v-for="polygon in currentElement.polygon" :key="polygon.pid">
          <bm-polygon :path="polygon.points" v-bind="getPolygonStyle(polygon.style)" :massClear="false" @click="showInfoWindow(polygon)"></bm-polygon>
          <bm-label v-if="polygon.showLabel" :content="polygon.title" :position="polygon.labelPosition || calculatePointsCenter(polygon.points)" :massClear="false" :offset="{ width: 0, height: 0 }"  :labelStyle="getLabelStyle(polygon.style)" @dblclick="handleEditPanelCommand(polygon)" @click="handleViewport(polygon.points)" title="单击聚焦 双击编辑"/>
        </div>
        <!-- 添加行政区划渲染 -->
        <div v-for="district in currentElement.district" :key="district.name">
          <bm-boundary 
            :name="district.name" 
            :strokeColor="district.color"
            :fillColor="district.color"
            :fillOpacity="district.opacity"
            :strokeWeight="1"
            :strokeOpacity="1"
            @load="(e) => onBoundaryLoad(e, district.name)"
            :data-district-name="district.name"
          ></bm-boundary>
        </div>
        <!-- 使用 Element Plus 的 Input 组件，移除右侧图标 -->
        <div v-if="isInputVisible" class="search-container">
          <el-autocomplete
            v-model="searchText"
            :fetch-suggestions="querySearch"
            placeholder="请输入命令"
            @select="handleSelect"
            @keyup.enter="handleSearch"
            @keydown.native="handleKeyDown"
            ref="searchInput"
            :trigger-on-focus="false"
            popper-class="command-suggestions"
          >
            <template #default="{ item }">
              <div class="suggestion-item">
                <!-- 简略格式 -->
                <template v-if="item.type === 'brief'">
                  <div class="brief-command">
                    <span class="command">{{ item.command }}</span>
                    <span class="description">{{ item.description }}</span>
                  </div>
                </template>
                
                <!-- 详细格式 -->
                <template v-else>
                  <div class="command-part">
                    <span class="command">{{ item.command }}</span>
                    <span class="description">{{ item.description }}</span>
                  </div>
                  <div class="usage-part">
                    <span class="usage-label">用法: </span>
                    <span class="usage-content">{{ item.usage }}</span>
                  </div>
                  <div v-if="item.params && item.params.length" class="params-part">
                    <div v-for="(param, index) in item.params" :key="index" class="param-item">
                      <span class="param-name">{{ param.name }}</span>
                      <span class="param-desc">{{ param.description }}</span>
                      <span v-if="param.required" class="required">*</span>
                    </div>
                  </div>
                  <div v-if="item.examples && item.examples.length" class="examples-part">
                    <div class="example-label">示例:</div>
                    <div v-for="(example, index) in item.examples" :key="index" class="example-item">
                      {{ example }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </el-autocomplete>
        </div>
        <!-- 全局信息窗体 -->
        <bm-info-window :position="infoWindow.position" :show="infoWindow.show" :title="infoWindow.title" :width="720" >
          <el-descriptions :column="1" border size="small">
            <el-descriptions-item v-for="(value, key) in infoWindow.content" :key="key" :label="key">
              {{ value }}
            </el-descriptions-item>
          </el-descriptions>
        </bm-info-window>
        <!-- 热力图组 -->
        <!-- <template v-if="mapLoaded"> -->
          <bml-heatmap 
            v-if="showHeatmap"
            :data="heatmapData" 
            :radius=20
            :opacity=0.7
            @init="onHeatmapInit"
            @load="onHeatmapLoad"
          >
          </bml-heatmap>
        <!-- </template> -->
      </baidu-map>
    </div>
    <!-- 使用 Element Plus 的 el-card 组件 -->
    <el-card v-if="showInfoPanel" class="info-panel" :style="{ maxHeight: maxHeight + 'px',width:categorizedPanelWidth }" shadow="hover">
      <template #header>
        <div class="panel-header">
          <div class="panel-title">
            <div class="title-row">
              <div class="title-main">{{ infoList.title?.text || '' }}</div>
            </div>
            <div v-if="infoList.title?.detail" class="title-conditions">
              {{ infoList.title.detail }}
            </div>
          </div>
          <el-button class="close-button" @click="handleClosePanel">
            <el-icon><Close /></el-icon>
          </el-button>
        </div>
      </template>
      
      <div class="el-card__body" :style="{ maxHeight: contentMaxHeight + 'px' }">
      <!-- info-panel-list 用于query结果列表 -->
      <div v-if="infoList.type === 'list'" class="info-panel-list">
        <div class="custom-list">
          <div v-for="(item, index) in infoList.data" :key="index" class="custom-list-item">
            <el-icon :size="16">
              <LocationFilled v-if="item.type === 'marker'" />
              <Share v-else-if="item.type === 'polyline'" />
              <Wallet v-else-if="item.type === 'polygon'" />
            </el-icon>
            <span class="truncate" :style="item.style">{{ item.content }}</span>
          </div>
        </div>
      </div>
      <!-- info-panel-categorized 用于show now分类面板 -->
      <div v-else-if="infoList.type === 'categorized'" class="info-panel-categorized">
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
          <el-tab-pane v-for="(group, groupTag) in infoList.data" :key="groupTag" :label="groupTag" :name="groupTag">
            
            <el-collapse v-model="activeCollapseItems[groupTag]" accordion>
              <el-collapse-item v-for="(subGroup, subGroupTitle) in group" :key="subGroupTitle" :name="subGroupTitle">
                <template #title>
                  <div class="collapse-item-header" @contextmenu.prevent="toggleSubGroupLabels(groupTag, subGroupTitle)">
                    <div :class="{ 'labels-hidden': !subGroupLabels[groupTag][subGroupTitle], 'title-text': true }">
                      {{ subGroupTitle }}
                      <el-badge 
                        :value="subGroup.length" 
                        :type="subGroupLabels[groupTag][subGroupTitle] ? 'primary' : 'info'"
                        class="subgroup-badge"
                      />
                    </div>
                    <div class="item-controls">
                      <el-button type="plain" size="small" @click.stop="removeSubGroup(groupTag, subGroupTitle)" ><el-icon><DeleteFilled /></el-icon></el-button>
                    </div>
                  </div>
                </template>
                <div class="custom-list">
                  <div v-for="item in subGroup" :key="item.pid" class="custom-list-item" @click="centerMapOnItem(item)">
                    <el-icon :size="16">
                      <LocationFilled v-if="item.type === 'marker'" />
                      <Share v-else-if="item.type === 'polyline'" />
                      <Wallet v-else-if="item.type === 'polygon'" />
                    </el-icon>
                    <span class="title-text">{{ item.title }}</span>
                    <div class="item-controls">
                      <el-switch
                        v-model="item.showLabel"
                        @change="toggleLabel(item)"
                      ></el-switch>
                      <el-button type="plain" size="small" @click.stop="removeItem(item, subGroup)"><el-icon><Delete /></el-icon></el-button>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            
          </el-tab-pane>
        </el-tabs>
      </div>
      
      <!-- info-panel-search 搜索结果面板 -->
      <div v-else-if="infoList.type === 'search'" class="info-panel-search">
        <div id="info-panel-content"></div>
      </div>

      <!-- info-panel-styleList ls手风琴面板 -->
      <div v-else-if="infoList.type === 'styleList'" class="info-panel-list">
        <el-collapse accordion>
          <el-collapse-item v-for="(group, tag) in infoList.data" :key="tag" :title="`${tag} `">
            <template #title>
              <span>{{ tag }}</span>
              <span class="group-count">({{ group.total }})</span>
            </template>
            <div v-for="item in group.items" :key="item.style" class="style-item">
              <span class="style-name">⤷ {{ item.style }}</span>
              <span class="style-count">{{ item.num }}</span>
            </div>
          </el-collapse-item>
        </el-collapse>
        </div>

      <!-- 用于district list -->
      <div v-else-if="infoList.type === 'districtList'" class="info-panel-list">
        <div v-for="item in infoList.data" :key="item.name" class="district-list-item">
          <span class="district-name">{{ item.name }}</span>
          <div class="district-controls">
            <el-color-picker 
              v-if="!item.name.startsWith('@')"
              v-model="item.color" 
              size="small" 
              @change="item.onColorChange"
            />
            <el-button type="plain" size="small" @click="item.onDelete" ><el-icon><DeleteFilled /></el-icon></el-button>
          </div>
        </div>
      </div>

      <!-- 用于editPanel -->
      <div v-else-if="infoList.type === 'editPanel'" class="edit-panel">
            <div class="edit-fields">
              <div v-for="(value, key) in Object.entries(infoList.data)" :key="key" class="edit-field">
                <el-input 
                  v-model="value[0]" 
                  placeholder="字段名" 
                  class="field-name"
                />
                <el-input 
                  v-model="value[1]" 
                  placeholder="请输入值" 
                  type="textarea" 
                  class="field-value"
                  :autosize="{ minRows: 1, maxRows: 5 }" 
                />
                <el-button @click="removeField(key)">删除</el-button>
              </div>
            </div>
            <el-button @click="addField">新增字段</el-button>
            <el-button @click="saveElementToDatabase">保存上传</el-button>
          
        
      </div>





      </div>

      
      
    </el-card>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, computed, watch } from 'vue'
import { Close, LocationFilled, Share, Wallet, Delete, DeleteFilled,User } from '@element-plus/icons-vue'
import { parseCommand } from './commandParser'
import { showMessage } from './utils/message'
import searchCommand from './commands/search'
import { ElDescriptions, ElDescriptionsItem, ElIcon,ElProgress } from 'element-plus'
import { BmlHeatmap } from 'vue-baidu-map-3x'
import { getHints } from './config/commandHints'
import { API_ENDPOINTS } from '@/config/api'
import mapManager from './utils/mapManager'
import drawCommand from './commands/draw'
import { hasPermission } from './config/permissions'
import customMapConfig from './config/custom_map_config.json'; // 导入 JSON 文件
import editPanelCommand from './editPanel/editPanel'
import { saveElementToDatabase } from './editPanel/editPanel'

export default {
  components: {
    Close,
    LocationFilled,
    Share,
    Wallet,
    Delete,
    DeleteFilled,
    User,
    ElDescriptions,
    ElDescriptionsItem,
    BmlHeatmap,
  },
  setup() {
    const isInputVisible = ref(false)
    const searchText = ref('')
    const searchInput = ref(null)
    const showInfoPanel = ref(false)
    const infoList = ref({ 
      type: '', 
      data: [],
      title: ''
    })
    const mapRef = ref(null)
    const BMap = ref(null)
    const mapInstance = ref(null)
    const mapCenter = ref({
      lng: 121.48,  // 上海市中心的经度
      lat: 31.22    // 上海市中心的纬度
    })
    const mapZoom = ref(14)
    const mapType = ref('BMAP_NORMAL_MAP')
    const maxHeight = ref(window.innerHeight * 0.9)
    const lastTarget = ref([])
    const currentElement = ref({
      marker: [],
      polyline: [],
      polygon: [],
      district: [] // 加 district 数
    })
    const globalStyle = ref(null)
    const invalidDistricts = ref([])
    const pendingDistricts = ref([])
    const searchKeyword = ref('')
    const searchLocation = ref('')
    const activeTab = ref('')
    const activeCollapseItems = ref({})
    const subGroupLabels = ref({})
    const infoWindow = ref({
      show: false,
      position: null,
      title: '',
      content: ''
    })
    const showHeatmap = ref(false)
    const heatmapData = ref([])
    const mapLoaded = ref(false)
    const localSearch = ref(null)
    const showNavigation = ref(false)

    const initComplete = ref(false)
    const loadingProgress = ref(0)
    const loadingMessage = ref('正在初始化...')
    const userRole = ref('guest')
    const isStyleLoaded = ref(false)
    const isPasswordMode = ref(false)
    const username = ref('')  // 添加用户名存储

    const specialOverlay = ref({
      district: [], // 用于存储使用 @ 语法创建的特殊行政区划覆盖物
      unsubmitted:[], //用于存储在页面上绘制但还没存储到数据库的覆盖物
    })

    const mapStyle = ref({ styleJson: customMapConfig  }); // 直接使用导入的 JSON 数据
    console.log('mapStyle:', mapStyle.value);

    // 处理绘制命令
    const handleDrawCommand = (type, e) => {
      const params = [];
      params.push(type);
      
      // 如果是绘制marker，从回调参数中获取坐标
      if (type === 'marker' && e?.point) {
        const position = `${e.point.lng},${e.point.lat}`;
        params.push(position);
        console.log('Drawing marker at position:', position);
      }
      
      // 调用 drawCommand
      drawCommand(params);
    };

  // 先定义 updateLoading 函数
  const updateLoading = async (message, progress) => {
    loadingMessage.value = message
    loadingProgress.value = progress
    console.log('Loading update:', { message, progress })
  }

  // 再定义 initialize 函数
  const initialize = async () => {
    try {
      // 测试数据库连接
      // await updateLoading('正在连接数据库...', 20)
      // await testDB()

      // // 加载全局样式
      // await updateLoading('正在加载样式配置...', 40)
      // await loadGlobalStyle()

      // 等待地图加载完成
      await updateLoading('正在加载地图...', 70)
      await new Promise(resolve => {
        if (mapLoaded.value) resolve()
        else watch(mapLoaded, (val) => {
          if (val) resolve()
        })
      })

      // 等待 bmap-draw 加载完成
      // await updateLoading('正在加载绘图工具...', 80)
      // await new Promise(resolve => {
      //   if (window.BMapDraw) resolve()
      //   else {
      //     const checkInterval = setInterval(() => {
      //       if (window.BMapDraw) {
      //         clearInterval(checkInterval)
      //         resolve()
      //       }
      //     }, 100)
      //   }
      // })

      // 初始化完成
      await updateLoading('加载完成', 100)
      setTimeout(() => {
        initComplete.value = true
      }, 500)

      // 清空特殊覆盖物
      specialOverlay.value = {
        district: []
      }

    } catch (error) {
      console.error('初始化失败:', error)
      await updateLoading('加载失败，请刷新页面重试', 0)
    }
  }

  // 添加切换地图类型的方法
  const setMapType = (type) => {
    mapType.value = type
  }


  const toggleInput = () => {
      if(isInputVisible.value) {
        hideInput()
      } else {
        showInput()
      }
    }  

    const showInput = () => {
      if (!isInputVisible.value) {
        isInputVisible.value = true
        nextTick(() => {
          searchInput.value.focus()
        })
      }
    }

    const hideInput = () => {
      isInputVisible.value = false
      searchText.value = ''
    }

    const setPasswordMode = (newMode) =>{
      isPasswordMode.value= newMode
    }

    const handleSearch = async () => {


        // 如果是密码模式，不执行命令解析
      if (isPasswordMode.value) {
        return
      }
      console.log('搜索内容:', searchText.value)

      const commandContext = { 
        // BMap: BMap.value, 
        // mapInstance: mapInstance.value,
        BMap,
        mapInstance,
        hideInput,
        showInfoPanel,
        infoList,
        lastTarget,
        currentElement,
        globalStyle,
        mapCenter,
        pendingDistricts,
        subGroupLabels,
        searchInput,
        closeInfoWindow,
        userRole,
        isPasswordMode,
        loadGlobalStyle,
        searchInput,
        isStyleLoaded,
        username,
        testDB,
        specialOverlay,
        setPasswordMode
  };

  if (searchText.value.startsWith('s ') || searchText.value.startsWith('search ')) {
    localSearch.value = await parseCommand(searchText.value, commandContext);
  } else {
    await parseCommand(searchText.value, commandContext);
  }



      showMessage(`命令已执行: ${searchText.value}`, 'success')
      // 关闭命令提示并全选输入框内容
      if (searchInput.value) {
        searchInput.value.activated = false;  // 闭 el-autocomplete 的下拉框
        searchInput.value.inputRef.select();  // 全选输入框内容
      }
    }

    const handleKeyDown = (event) => {
      // 对于 = 和 - 键，完全阻止事件
      if (event.key === '=' || event.key === '-') {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation(); // 添加这一行，阻止所有后续的事件处理
        return false; // 确保返 false
      }
    }

    const onMapReady = ({ BMap: BMapInstance, map }) => {
      console.log('Map ready:', { BMapInstance, map })
      // map.setMapStyleV2({     
      //     styleId: 'e2b30bd5d31a50a2e6bfa855c2ee18be'
      // })      
      // 初始化地图管理器
      initialize();
      // mapManager.initialize(BMapInstance, map)
      mapManager.initialize({
        BMapInstance,
        map,
        searchInput,
        isInputVisible,
        showInput,
        hideInput,
        setPasswordMode,
        showInfoPanel,
        infoList,
        currentElement,
        globalStyle,
        specialOverlay,
        pendingDistricts,
        userRole,
        username,
        isStyleLoaded,
        loadGlobalStyle,
        lastTarget,
        closeInfoWindow,
        calculatePointsCenter
      })
      


      

      // 合并触摸事件处理
      const handleTouch = (event) => {
        // 阻止事件冒泡
        event.stopPropagation();
        
        // 如果是地图区域的触摸，优先处理地图事件
        if (event.target.closest('.bm-view')) {
          console.log('Map longpress detected');
          showMessage('Map longpress detected','success')
          if (showInfoPanel.value) {
            showInfoPanel.value = false;
          }
          if (!isInputVisible.value)
              showInput()
            else
              hideInput()
        } else {
          console.log('Window touch detected');
          showMessage('window touch detected','warning')
        }
      };
        // 为地图添加触摸事件监听
        map.addEventListener('longpress', handleTouch, { passive: false });
        // 为窗口添加触摸事件监听
        window.addEventListener('touchend', handleTouch, { passive: false });
      
        mapLoaded.value = true
      // window.addEventListener('touchend',()=>{
      //     showMessage("touchend@window","success")
      //     if (!isInputVisible.value)
      //       showInput()
      //     else
      //       hideInput()
      // })
      
           // console.log(newBound)
      // map.addDistrictLayer(dist);

      // 更新全局 BMapGL 对象
      // Object.assign(window.BMapGL, BMapInstance);
      // console.log('BMapGL updated:', window.BMapGL);

			// //右上角卫星图切换
			// const top_right_navigation = new BMapGL.MapTypeControl({mapTypes: [BMAP_NORMAL_MAP,BMAP_HYBRID_MAP], anchor: BMAP_ANCHOR_TOP_RIGHT  });  
			// map.addControl(top_right_navigation);
      // 在地图准备好后加载 bmap-draw
      // import('bmap-draw').then(module => {
      //   console.log('Loading bmap-draw...');
      //   window.BMapDraw = module.default || module;
      //   console.log('bmap-draw loaded successfully');
      // }).catch(error => {
      //   console.error('Failed to load bmap-draw:', error);
      // });      
    }

    const getMarkerStyle = (styleKey) => {
      if (!globalStyle.value) return {}
      return globalStyle.value[styleKey]?.marker || {}
    }

    const getPolylineStyle = (styleKey) => {
      if (!globalStyle.value) return {}
      return globalStyle.value[styleKey]?.polyline || {}
    }

    const getPolygonStyle = (styleKey) => {
      if (!globalStyle.value) return {}
      return globalStyle.value[styleKey]?.polygon || {}
    }

    const getLabelStyle = (styleKey) => {
      if (!globalStyle.value) return {}
      return globalStyle.value[styleKey]?.label || {}
    }

    const testDB = async () => {
      try {
        console.log('Testing DB connection with endpoint:', API_ENDPOINTS.TEST_DB);
        const response = await fetch(API_ENDPOINTS.TEST_DB)
        const result = await response.text()
        console.log(result)
        return true;
      } catch (error) {
        console.error('Failed to connect to the database:', error)
        console.error('API_BASE_URL:', API_BASE_URL)
        console.error('Current endpoint:', API_ENDPOINTS.TEST_DB)
      }
    }

    const loadGlobalStyle = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.GLOBAL_STYLE)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        // console.log('Raw result:', result);
        
        // 解析每个样式的 valueString 和 seq
        const styles = {}
        result.forEach((item, index) => {
          try {
            // console.log(`Processing item ${index}:`, item);
            // console.log('valueString:', item.valueString);
            const parsedStyle = JSON.parse(item.valueString)  // 解析 valueString
            // console.log('Parsed style:', parsedStyle);
            Object.entries(parsedStyle).forEach(([styleName, styleValue]) => {
              styles[styleName] = {
                ...styleValue,
                seq: item.seq  // 添加 seq 信息
              }
            })
          } catch (parseError) {
            console.error(`Error parsing item ${index}:`, parseError)
            console.error('Problematic valueString:', item.valueString)
          }
        })
        
        globalStyle.value = styles
        console.log('Final global styles:', globalStyle.value)
        return true;
      } catch (error) {
        console.error('Failed to load global style:', error)
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack
        })
      }
    }

    const onBoundaryLoad = (e, districtName) => {
      console.log('Boundary load result:', e)
      console.log('District name:', districtName)

      if (e.boundaries.length === 0) {
        invalidDistricts.value.push(districtName)
      } else {
        // 只有成功加载的区划才保留在 currentElement.district 中
        currentElement.value.district = currentElement.value.district.filter(d => d.name !== districtName)
        currentElement.value.district.push(pendingDistricts.value.find(d => d.name === districtName))
      }

      pendingDistricts.value = pendingDistricts.value.filter(d => d.name !== districtName)

      // 检查是否所有区划都已加载完成
      if (pendingDistricts.value.length === 0) {
        if (invalidDistricts.value.length > 0) {
          const message = `以下行政区划无法加：${invalidDistricts.value.join(', ')}`
          showMessage(message, 'warning')
          console.warn('WARNING: ' + message)
          console.warn('Invalid districts:', invalidDistricts.value)
        } else {
          console.log('All districts loaded successfully')
        }
        invalidDistricts.value = []
      }
    }

    const handleTabClick = (tab) => {
      const groupTag = tab.props.name
      if (infoList.value.data[groupTag] && !activeCollapseItems.value[groupTag]) {
        const firstSubGroup = Object.keys(infoList.value.data[groupTag])[0]
        if (firstSubGroup) {
          activeCollapseItems.value[groupTag] = [firstSubGroup]
        }
      }
    }

    // 添加 watch 来监听 activeTab 的变
    watch(activeTab, (newActiveTab) => {
      if (infoList.value.data[newActiveTab]) {
        const firstSubGroup = Object.keys(infoList.value.data[newActiveTab])[0]
        if (firstSubGroup) {
          activeCollapseItems.value[newActiveTab] = [firstSubGroup]
        }
      }
    })

    const categorizedPanelWidth = computed(() => {
      // console.log('infoList.value.type:', infoList.value.type)
      if (infoList.value.type === 'categorized') {
        const groupCount = Object.keys(infoList.value.data).length
        return Math.min(Math.max(groupCount * 75, 400), window.innerWidth * 0.8) + 'px'
      }
      return '300px'
    })

    const truncateTitle = (title) => {
      return title.length > 20 ? title.slice(0, 20) + '...' : title
    }

    const toggleLabel = (item) => {
      const thisElement = currentElement.value[item.type].find(m => m.pid === item.pid)
      if (thisElement) {
        thisElement.showLabel = item.showLabel
        // 强制重新渲染地图元素
        currentElement.value = { ...currentElement.value }
      }
    }

    const removeItem = (item, subGroup) => {
      const index = subGroup.findIndex(i => i.pid === item.pid)
      if (index !== -1) {
        subGroup.splice(index, 1)
        // 从地图上移除对应的素
        if (item.type === 'marker') {
          currentElement.value.marker = currentElement.value.marker.filter(m => m.pid !== item.pid)
        } else if (item.type === 'polyline') {
          currentElement.value.polyline = currentElement.value.polyline.filter(p => p.pid !== item.pid)
        } else if (item.type === 'polygon') {
          currentElement.value.polygon = currentElement.value.polygon.filter(p => p.pid !== item.pid)
        }

        // 刷新 infoList，清除空 group 和 subgroup
        if (infoList.value.type === 'categorized') {
          const updatedData = {}
          let currentGroupTag = activeTab.value
          
          Object.entries(infoList.value.data).forEach(([groupTag, group]) => {
            const updatedGroup = {}
            Object.entries(group).forEach(([subGroupTitle, subGroupItems]) => {
              if (subGroupItems.length > 0) {
                updatedGroup[subGroupTitle] = subGroupItems
              }
            })
            if (Object.keys(updatedGroup).length > 0) {
              updatedData[groupTag] = updatedGroup
            }
          })
          infoList.value.data = updatedData

          // 检查当前 group 是否还存在
          nextTick(() => {
            if (updatedData[currentGroupTag]) {
              // 当前 group 还存在，检查当前 subGroup 是否为空
              const currentSubGroups = Object.keys(updatedData[currentGroupTag])
              if (currentSubGroups.length > 0) {
                // 如果当前 subGroup 为空，展开第一个 subGroup
                if (!subGroup.length) {
                  activeCollapseItems.value[currentGroupTag] = [currentSubGroups[0]]
                }
              }
            } else {
              // 当前 group 不在了，切换到第一个 group
              const groups = Object.keys(updatedData)
              if (groups.length > 0) {
                activeTab.value = groups[0]
                const firstSubGroup = Object.keys(updatedData[groups[0]])[0]
                if (firstSubGroup) {
                  activeCollapseItems.value[groups[0]] = [firstSubGroup]
                }
              }
            }
          })
        }

        // 强制重新渲染
        infoList.value = { ...infoList.value }
        currentElement.value = { ...currentElement.value }
      }
    }

    const initializeActiveCollapseItems = () => {
      if (infoList.value.type === 'categorized' && !Object.keys(activeCollapseItems.value).length) {
        const groups = Object.keys(infoList.value.data)
        if (groups.length > 0) {
          activeTab.value = groups[0] // 设置第一个 Tab 激活状态
          activeCollapseItems.value = {}
          groups.forEach(groupTag => {
            const firstSubGroup = Object.keys(infoList.value.data[groupTag])[0]
            if (firstSubGroup) {
              activeCollapseItems.value[groupTag] = [firstSubGroup]
            }
          })
        }
      }
    }

    const initializeSubGroupLabels = () => {
      if (infoList.value.type === 'categorized') {
        Object.entries(infoList.value.data).forEach(([groupTag, group]) => {
          if (!subGroupLabels.value[groupTag]) {
            subGroupLabels.value[groupTag] = {}
          }
          Object.keys(group).forEach(subGroupTitle => {
            if (subGroupLabels.value[groupTag][subGroupTitle] === undefined) {
              subGroupLabels.value[groupTag][subGroupTitle] = true
            }
          })
        })
      }
    }

    const toggleSubGroupLabels = (groupTag, subGroupTitle) => {
      console.log('Before toggle:', JSON.stringify(subGroupLabels.value))
      const currentValue = subGroupLabels.value[groupTag][subGroupTitle]
      console.log('groupTag/subGroupTitle:', groupTag, subGroupTitle)
      console.log('currentValue:', currentValue)
      const newValue = !currentValue
      console.log('newValue:', newValue)
      
      // 更新 subGroupLabels
      subGroupLabels.value[groupTag][subGroupTitle] = newValue

      // 更新 infoList 中的 showLabel
      infoList.value.data[groupTag][subGroupTitle].forEach(item => {
        item.showLabel = newValue
        toggleLabel(item)
      })

      // 保存当前展开的 subGroups
      const currentlyOpenSubGroups = activeCollapseItems.value[groupTag] || []

      // 强制新渲染 infoList
      infoList.value = { ...infoList.value }

      // 在一个 tick 恢展开 subGroups
      nextTick(() => {
        activeCollapseItems.value[groupTag] = currentlyOpenSubGroups
      })

      console.log('After toggle:', JSON.stringify(subGroupLabels.value))
      // 在一小段延迟后再检查值
      setTimeout(() => {
        console.log('After delay:', JSON.stringify(subGroupLabels.value))
      }, 100)
    }

    const removeSubGroup = (groupTag, subGroupTitle) => {
      const subGroup = infoList.value.data[groupTag][subGroupTitle]
      
      // 从 currentElement 中移除所有该 subGroup 中的元素
      subGroup.forEach(item => {
        if (item.type === 'marker') {
          currentElement.value.marker = currentElement.value.marker.filter(m => !subGroup.some(s => s.pid === m.pid))
        } else if (item.type === 'polyline') {
          currentElement.value.polyline = currentElement.value.polyline.filter(p => !subGroup.some(s => s.pid === p.pid))
        } else if (item.type === 'polygon') {
          currentElement.value.polygon = currentElement.value.polygon.filter(p => !subGroup.some(s => s.pid === p.pid))
        }
      })

      // 在删除前获取当前 subGroup 的索引
      const currentSubGroups = Object.keys(infoList.value.data[groupTag])
      const currentSubGroupIndex = currentSubGroups.indexOf(subGroupTitle)

      // 从 infoList 移除该 subGroup
      delete infoList.value.data[groupTag][subGroupTitle]
      delete subGroupLabels.value[groupTag][subGroupTitle]
      
      if (Object.keys(infoList.value.data[groupTag]).length === 0) {
        delete infoList.value.data[groupTag]
        delete subGroupLabels.value[groupTag]
      }

      // 检查并选择合适的 Tab 和 subGroup
      nextTick(() => {
        if (infoList.value.data[groupTag]) {
          // 当前 Tab 还有 subGroup
          const remainingSubGroups = Object.keys(infoList.value.data[groupTag])
          if (currentSubGroupIndex === remainingSubGroups.length) {
            // 如果是后一个，选择前一个
            const prevSubGroup = remainingSubGroups[remainingSubGroups.length - 1]
            if (prevSubGroup) {
              activeCollapseItems.value[groupTag] = [prevSubGroup]
            }
          } else {
            // 否则选择下一个
            const nextSubGroup = remainingSubGroups[currentSubGroupIndex]
            if (nextSubGroup) {
              activeCollapseItems.value[groupTag] = [nextSubGroup]
            }
          }
        } else {
          // 当前 Tab 已空，选择其他 Tab
          const groups = Object.keys(infoList.value.data)
          const currentGroupIndex = groups.indexOf(groupTag)
          
          // 如果删除的是最后一个 Tab，选择前一个 Tab
          if (currentGroupIndex === -1 && groups.length > 0) {
            const previousGroup = groups[groups.length - 1]  // 选择倒数第二个 Tab
            if (previousGroup) {
              activeTab.value = previousGroup
              const firstSubGroup = Object.keys(infoList.value.data[previousGroup])[0]
              if (firstSubGroup) {
                activeCollapseItems.value[previousGroup] = [firstSubGroup]
              }
            }
          } else if (currentGroupIndex >= 0) {
            // 否则选择下一个 Tab
            const nextGroup = groups[currentGroupIndex]
            if (nextGroup) {
              activeTab.value = nextGroup
              const firstSubGroup = Object.keys(infoList.value.data[nextGroup])[0]
              if (firstSubGroup) {
                activeCollapseItems.value[nextGroup] = [firstSubGroup]
              }
            }
          }
        }
      })

      // 强制更新视图
      infoList.value = { ...infoList.value }
      currentElement.value = { ...currentElement.value }
    }

    let previousInfoListType = ''

    watch(() => infoList.value.type, (newType) => {
      if (newType === 'categorized') {
        nextTick(() => {
          const groups = Object.keys(infoList.value.data)
          if (groups.length > 0) {
            activeTab.value = groups[0]  // 设置第一个 Tab 为激活状态
            // 确保第一个 Tab 的第个 subGroup 是展开的
            if (!activeCollapseItems.value[groups[0]]) {
              activeCollapseItems.value[groups[0]] = []
              const firstSubGroup = Object.keys(infoList.value.data[groups[0]])[0]
              if (firstSubGroup) {
                activeCollapseItems.value[groups[0]] = [firstSubGroup]
              }
            }
          }
        })
      }
    }, { immediate: true })

    watch(activeTab, (newActiveTab) => {
      if (infoList.value.data[newActiveTab]) {
        const firstSubGroup = Object.keys(infoList.value.data[newActiveTab])[0]
        if (firstSubGroup) {
          activeCollapseItems.value[newActiveTab] = [firstSubGroup]
        }
      }
    })

    const centerMapOnItem = (item) => {
      console.log('centerMapOnItem called with item:', item)
      console.log('mapInstance:', mapInstance.value)
      console.log('BMap:', BMap.value)

      // 隐藏命令输入框
      hideInput()

      if (!mapInstance.value || !BMap.value) {
        console.error('mapInstance or BMap is not initialized')
        return
      }

      if (!item.points || item.points.length === 0) {
        console.error('Item has no valid points:', item)
        return
      }

      try {
        const point = new BMap.value.Point(item.points[0].lng, item.points[0].lat)
        console.log('Created point:', point)
        
        nextTick(() => {
          mapInstance.value.centerAndZoom(point, 18)
          console.log('Map center set to:', point)
        })
      } catch (error) {
        console.error('Error in centerMapOnItem:', error)
      }
    }

    const showInfoWindow = (item) => {
      console.log('showInfoWindow called for item:', item.pid)
      console.log('infoWindow state before operation:', JSON.stringify(infoWindow.value))
      
      let content = {}
      if (typeof item.detail === 'string') {
        try {
          content = JSON.parse(item.detail)
        } catch (e) {
          console.error('Failed to parse item detail:', e)
          content = { detail: item.detail }
        }
      } else if (typeof item.detail === 'object') {
        content = item.detail
      } else {
        content = { detail: String(item.detail) }
      }

      infoWindow.value = {
        show: true,
        position: item.points[0],
        title: item.title,
        content: content
      }
      // infoWindow.redraw()
      
      console.log('infoWindow state after operation:', JSON.stringify(infoWindow.value))
    }

    const closeInfoWindow = () => {
      infoWindow.value.show = false
    }

    const toggleHeatmap = async () => {
      showHeatmap.value = !showHeatmap.value
    }

    const handleClosePanel = () => {
      console.log('handleClosePanel called')
      console.log('Current localSearch value:', localSearch.value)
      if (infoList.value.type === 'search' && localSearch.value) {
        console.log('Clearing search results...')
        localSearch.value.clearResults()
        localSearch.value = null
        console.log('localSearch value after clearing:', localSearch.value)
      }
      showInfoPanel.value = false
    }


    const calculatePointsCenter = (points) => {
        if (!points || points.length === 0) return null;
        
        // 计算所有点的平均值
        const sumLat = points.reduce((sum, point) => sum + point.lat, 0);
        const sumLng = points.reduce((sum, point) => sum + point.lng, 0);
        // console.log({
        //   lng: sumLng / points.length,
        //   lat: sumLat / points.length
        //   })
        return {
          lng: sumLng / points.length,
          lat: sumLat / points.length
          };
    }

    // 监控 heatmapData 的变化
    watch(() => heatmapData.value, (newData) => {
      console.log('heatmapData changed:', newData)
    })

    // 监控 showHeatmap 的变化
    watch(() => showHeatmap.value, (newValue) => {
      console.log('showHeatmap changed:', newValue)
    })

    // 热力图组件初始化回调
    const onHeatmapInit = (instance) => {
      console.log('Heatmap component initialized:', {
        instance,
        dataLength: heatmapData.value?.length || 0
      })
    }

    // 热力图组载回调
    const onHeatmapLoad = (overlay) => {
      console.log('Heatmap component loaded:', {
        overlay,
        dataLength: heatmapData.value?.length || 0
      })
    }

    const querySearch = (queryString, cb) => {
      const hints = getHints(queryString)
      cb(hints)
    }

    const handleSelect = (item) => {
      if (item.command) {
        searchText.value = item.command
      }
    }


    // 测试移动端的反馈，但目前没有
    const testMobile = (e) => {
       mapZoom.value = 17
      showMessage(e.point,'warning')
    }

    // 监听地图缩放级别变化
    const syncCenterAndZoom = (e) => {
      
      // const { lng, lat } = e.target.getCenter();
      mapZoom.value = e.target.getZoom()
      // console.log(mapZoom.value)
      showNavigation.value = mapZoom.value > 18
  // nextTick(() => {
      //   center.value.lng = lng;
      //   center.value.lat = lat;
      // })
    }

    
    const contentMaxHeight = computed(() => {
      return maxHeight.value - 90  // 90px 是标题区域的度
    })

    // 添加计算属性
    const hasRightClickMenuPermission = computed(() => {
      return hasPermission(userRole.value, 'rightClickMenu', 'UI')
    })

    const handleEditPanelCommand = (e) => {
      // const elementId = e.pid; // 假设您在元素上设置了 data-id 属性
      console.log( e.pid )
      editPanelCommand(e.pid);
    };

    const handleViewport = (points) => {
      if (points.length === 1) {
        const point = points[0];
        // 创建一个新的 BMapGL.Point 实例
        const bMapPoint = new BMapGL.Point(point.lng, point.lat);
        // 使用 panTo 方法将地图中心移动到该点
        mapRef.value.map.panTo(bMapPoint);
        // 设置缩放级别
        mapRef.value.map.setZoom(16);
      } else if (points.length > 1) {
        // 创建多个 BMapGL.Point 实例
        const bMapPoints = points.map(p => new BMapGL.Point(p.lng, p.lat));
        // 使用 setViewport 方法设置视口
        mapRef.value.map.setViewport(bMapPoints);
      }
    };
    

    onMounted(() => {
      window.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          event.preventDefault()
          if (isInputVisible.value)
            hideInput()
          else
            showInput()
        } else if (event.key === 'Escape') {
          hideInput()
          // 只在非搜索面板时关闭信息面板
          if (infoList.value.type !== 'search') {
            showInfoPanel.value = false
          }
        }
      })

      // 听窗口大小变化更新 maxHeight
      window.addEventListener('resize', () => {
        maxHeight.value = window.innerHeight * 0.9
      })

      // // 测试数据库连接
      // testDB()

      // loadGlobalStyle()

      // 初始化时展开第一个 Tab 第一个 subGroup
      if (infoList.value.type === 'categorized' && Object.keys(infoList.value.data).length > 0) {
        initializeActiveCollapseItems()
      }

      // 监听 infoList 的变化
      watch(() => infoList.value, (newInfoList) => {
        if (newInfoList.type === 'categorized') {
          initializeActiveCollapseItems()
          initializeSubGroupLabels()
        }
      }, { deep: true })
    })

    return {
      isInputVisible,
      searchText,
      searchInput,
      showInput,
      hideInput,
      toggleInput,
      handleSearch,
      handleKeyDown,
      showInfoPanel,
      infoList,
      mapRef,
      onMapReady,
      mapCenter,
      mapZoom,
      maxHeight,
      lastTarget,
      currentElement,
      globalStyle,
      getMarkerStyle,
      getPolylineStyle,
      getPolygonStyle,
      getLabelStyle,
      onBoundaryLoad,
      pendingDistricts,
      searchKeyword,
      searchLocation,
      activeTab,
      activeCollapseItems,
      handleTabClick,
      categorizedPanelWidth,
      truncateTitle,
      toggleLabel,
      removeItem,
      subGroupLabels,
      toggleSubGroupLabels,
      removeSubGroup,
      centerMapOnItem,
      infoWindow,
      showInfoWindow,
      closeInfoWindow,
      showHeatmap,
      heatmapData,
      toggleHeatmap,
      mapLoaded,
      localSearch,
      handleClosePanel,
      onHeatmapInit,
      onHeatmapLoad,
      querySearch,
      handleSelect,
      showNavigation,
      syncCenterAndZoom,
      testMobile,
      contentMaxHeight,
      initComplete,
      loadingProgress,
      loadingMessage,
      calculatePointsCenter,
      mapType,
      setMapType,
      isPasswordMode,
      isStyleLoaded,
      username,
      userRole,
      specialOverlay,
      hasPermission,
      hasRightClickMenuPermission,  // 返回计算属性而不是直接返回 hasPermission 函数
      handleDrawCommand,
      searchInput,
      setPasswordMode,
      mapStyle,
      handleEditPanelCommand,
      handleViewport
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  outline: none;
}

.map-container {
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.bm-view {
  width: 100%;
  height: 100%;
}

.search-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 300px;
}

/* 覆盖 Element Plus 的一些样式以适应地图 */
.search-container .el-input__wrapper {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* 添加 infoPanel 样式 */
.info-panel {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: white;
  width: 400px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.panel-header {
  position: relative;
  padding: 8px 0;
  border-bottom: 1px solid #EBEEF5;
  background-color: #F5F7FA;
  margin: -1px -12px 0;
  padding: 8px 12px;
}

.panel-title {
  padding-right: 24px;  /* 为关闭按钮留出空间 */
}

.title-row {
  margin-bottom: 8px;
}

.title-main {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}

.title-conditions {
  font-size: 14px;
  color: #606266;
  line-height: 1.4;
  max-height: 2.8em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.close-button {
  position: absolute;
  top: 4px;
  right: 0;
  padding: 4px;
  height: auto;
  min-height: 0;
}

.close-button .el-icon {
  font-size: 16px;
}

.panel-content {
  overflow-y: auto;
  /* padding: 12px; */
}

/* 移除 el-card 的默认样式 */
.info-panel .el-card__header {
  padding: 0 12px;
  border-bottom: none;
}

/* 确保卡片内容区域有合适的内边距 */
.el-card__body {
  padding: 12px;
}


.truncate {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 修改 info-panel-content 样式 */
#info-panel-content {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  min-height: 200px;
}

/* 确保 el-card__body 也能正确扩展 */
.info-panel .el-card__body {
  flex: 1;
  padding: 0 !important; /* 除默认内边距 */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
}

/* 调整表样式以适应新的局 */
.info-panel .el-list {
  overflow-y: auto;
  flex: 1;
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除默认外边 */
}

.info-panel-list {
  flex: 1;
  overflow-y: auto;
  /* width: 100%; */
  padding: 12px;
  /* box-sizing: border-box; */
  min-height: 0;
}


.info-panel-categorized {
  
  overflow: auto;
  /* flex: 1; */
  /* display: flex; */
  /* flex-direction: column; */
}


.info-panel-search {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}


.info-panel .el-list {
  padding: 0;
  margin: 0;
}

.list-item {
  padding: 5px;
  color: black; /* 确保文字颜色为黑色 */
}

.truncate {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit; /* 继承父元素的颜色 */
}

/* 添加以下新样式 */
.el-list-item__content {
  color: black; /* 确保列表项内容的文字颜色为黑色 */
}

.info-panel .el-card__body {
  background-color: rgba(255, 255, 255, 0.8); /* 添加一个半透明的白色背景 */
}

.categorized-panel {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #dcdfe6;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 80%;
  height: auto;
  min-height: 30%;
  max-height: 80%;
  overflow: auto;
  padding: 10px;
}

.categorized-panel .el-tabs__content {
  padding: 10px 0;
}

.categorized-panel .el-collapse {
  border: none;
  display: flex;
  flex-direction: column;
}

.categorized-panel .el-collapse-item {
  width: 100%;
  margin-bottom: 10px; /* 添加底部间距 */
}

.categorized-panel .el-collapse-item__header {
  padding: 0 10px;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
}

.categorized-panel .el-collapse-item__content {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.categorized-panel .custom-list {
  padding: 0;
}

.categorized-panel .custom-list-item {
  padding: 5px 0;
  display: flex;
  align-items: center;
  width: 100%;
  transition: background-color 0.3s ease; /* 添加过渡效果 */
  cursor: pointer;
}

.categorized-panel .custom-list-item:hover {
  background-color: #f0f0f0; /* 鼠标停时的背景色 */
}

.categorized-panel .custom-list-item .el-icon {
  flex-shrink: 0;
  margin-right: 10px;
}

.categorized-panel .custom-list-item span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categorized-panel .item-controls {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.categorized-panel .item-controls .el-switch {
  margin-right: 10px;
}

.categorized-panel .el-switch__label {
  font-size: 12px;
}

.collapse-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 0; /* 确保子元素可以正缩短 */
  cursor: context-menu;
}

.collapse-item-header:hover {
  background-color: #f5f7fa;
}

.collapse-item-header .item-controls {
  display: flex;
  align-items: center;
}

.collapse-item-header .el-switch {
  margin-right: 10px;
}

.labels-hidden {
  font-style: italic;
  color: #999;
}

/* 修改 infoWindow 中的 Descriptions 组件样式 */


.el-descriptions {
  width: 100%;
  max-height:650px;
  padding-bottom: 10px;
}

.el-descriptions__cell {
  padding: 3px 12px;
}

.el-descriptions__label {
  width: 35%;
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
}

.el-descriptions__content {
  width: 65%;
  white-space: normal;
  word-break: break-word;
}

/* 修改标题文本样式 */
.title-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0; /* 这个属性很重要，保文本可以正确缩短 */
  padding-right: 10px; /* 给删除按钮留出空间 */
}

.custom-list-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  min-width: 0; /* 确保元可以正确短 */
}

.custom-list-item .el-icon {
  flex-shrink: 0;
  margin-right: 12px; /* 增加图标与文本之间的间距 */
  margin-left: 8px;  /* 增加左侧间距 */
}

.item-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0; /* 防止控件被压缩 */
}

/* 确保 el-tabs 内的标签文本也能正确显示 */
.el-tabs__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* 或其他合适的值 */
  padding: 10px 15px !important;
  text-align: center;
}

.el-tabs__nav {
  width: 100%;
  display: flex !important;
  justify-content: space-around;  /* 平均分配空 */
}

/* ... 其他样式保持不变 ... */

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dcdfe6;
}

.panel-header-right {
  margin-left: auto; /* 将右内容推到最右边 */
}

.panel-header .el-button {
  padding: 5px;
}

.command-suggestions {
  min-width: 400px;
}

.suggestion-item {
  padding: 2px 8px;
}

.brief-command {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  line-height: 24px;
}

.command {
  font-weight: bold;
  color: #409EFF;
  margin-right: 12px;
  white-space: nowrap;
}

.description {
  color: #666;
  font-size: 0.9em;
  flex: 1;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 详细视图的样式 */
.command-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.usage-part {
  margin: 4px 0;
  font-family: monospace;
  font-size: 0.9em;
  color: #67C23A;
}

.usage-label {
  color:#909399;
}

.usage-content {
  margin-left: 4px;
  color:#909399;
}

.params-part {
  margin: 4px 0;
  padding-left: 12px;
  border-left: 2px solid #eee;
}

.param-item {
  margin: 2px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
}

.param-name {
  color: #67C23A;
  font-family: monospace;
}

.param-desc {
  color: #666;
}

.required {
  color: #F56C6C;
  margin-left: 4px;
}

.examples-part {
  margin-top: 4px;
  font-size: 0.9em;
}

.example-label {
  color: #909399;
  margin-bottom: 2px;
}

.example-item {
  color: #606266;
  font-family: monospace;
  padding: 1px 0;
  padding-left: 12px;
}

/* 确保下拉菜单不会出现滚动条 */
.el-autocomplete-suggestion__wrap {
  max-height: none !important;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  width: 300px;
  text-align: center;
}

.loading-text {
  margin-top: 20px;
  color: #606266;
  font-size: 14px;
}

.el-progress {
  margin-bottom: 20px;
}

.style-item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  margin-left: 20px;  /* 添加左边距 */
}

.style-name {
  color: #303133;
  display: flex;
  align-items: center;
}

.style-count {
  color: #409EFF;  /* 使用 Element Plus 的主题色 */
  font-weight: bold;
  background-color: #ecf5ff;  /* 浅蓝色背景 */
  padding: 2px 8px;
  border-radius: 10px;
  min-width: 24px;
  text-align: center;
}

.group-count {
  color: #409EFF;
  margin-left: 8px;
  font-weight: bold;
}

/* 确保 el-collapse 内容正确显示 */
.info-panel-list .el-collapse {
  border: none;
}

.info-panel-list .el-collapse-item__content {
  padding-bottom: 0;
}

.district-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #EBEEF5;
}

.district-name {
  flex: 1;
  margin-right: 12px;
}

.district-controls {
  display: flex;
  gap: 8px;
  align-items: center;
}

.map-type-control {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}


.map-type-control .el-button-group {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.user-info {
  position: absolute;
  top: 10px;
  left: 100px;
  z-index: 1000;
  background-color: white;
  padding: 6px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.username {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #606266;
  cursor: default;
}

.username .el-icon {
  font-size: 16px;
}


.edit-fields {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
}

.edit-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%; /* 使每行占满宽度 */
}

.field-name {
  flex: 1; /* 字段名占据剩余空间 */
  margin-right: 10px; /* 添加右边距 */
}

.field-value {
  flex: 2; /* 数据输入框占据更多空间 */
  margin-right: 10px; /* 添加右边距 */
}

.close-button {
  margin-left: auto; /* 将关闭按钮推到右侧 */
}
</style>





