import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

export default function districtCommand(params) {
  const { map, BMapGL } = mapManager;
  const { currentElement, specialOverlay,pendingDistricts } = mapManager.getElementControls();
  const { showInfoPanel, infoList } = mapManager.getPanelControls();

  if (!map || !BMapGL) {
    showMessage('地图实例未初始化', 'error')
    return
  }
  
  if (params.length === 0) {
    showMessage('district命令参数错误', 'warning');
    return;
  }

  const [firstParam, color = '#FF0000', opacity = 0.2] = params;

  // 处理 ls 或 list 命令
  if (firstParam === 'ls' || firstParam === 'list') {
    showInfoPanel.value = true;
    updateDistrictList();
    return;
  }

  // 处理 -all 命令
  if (firstParam === '-all') {
    currentElement.value.district = [];
    pendingDistricts.value = [];
    // 清除特殊覆盖物
    if (specialOverlay.value.district.length > 0) {
      specialOverlay.value.district.forEach(item => {
        console.log(item.name,item.overlay)
        if (item.overlay) map.removeOverlay(item.overlay);
      });
      specialOverlay.value.district = [];
    }
    showInfoPanel.value = false;
    showMessage('已清除所有行政区划', 'success');
    console.log('All districts removed');
    return;
  }

  var isRemove = '+'
  if(firstParam.startsWith('-'))
      isRemove = '-'
  else if (firstParam.startsWith('@'))
      isRemove = '@'

  console.log("isRemove:", isRemove)

  const districtList = firstParam.replace(/^[+-@]/, '').split(/[,，、/|]/);
  
  // 减号- 只能删除通过 + 增加进来的district，这些调用的是3x组件
  if (isRemove==='-') {
    // Remove districts
    currentElement.value.district = currentElement.value.district.filter(
      d => !districtList.includes(d.name)
    );
    pendingDistricts.value = pendingDistricts.value.filter(
      d => !districtList.includes(d.name)
    );
  } else if (isRemove === '+') {
    districtList.forEach(name => {
      const districtData = { name, color, opacity: parseFloat(opacity) };
      
      const existingIndex = currentElement.value.district.findIndex(d => d.name === name);
      if (existingIndex !== -1) {
        currentElement.value.district[existingIndex] = districtData;
      } else {
        currentElement.value.district.push(districtData);
      }

      const pendingIndex = pendingDistricts.value.findIndex(d => d.name === name);
      if (pendingIndex !== -1) {
        pendingDistricts.value[pendingIndex] = districtData;
      } else {
        pendingDistricts.value.push(districtData);
      }
    });
  } else if (isRemove === '@') {
    const toMask = '(' + districtList.join(',') + ")";
    const dist = new BMap.DistrictLayer();
    dist.searchBoundary({
      name: toMask
    }, (e) => {
      const points = [];
      e.boundaries.forEach((item) => {
        item.split(";").forEach((lnglat) => {
          const thisPoint = new BMap.Point(lnglat.split(',')[0], lnglat.split(',')[1]);
          points.push(thisPoint);
        });
      });

      const mapMask = new BMap.MapMask(
        points,
        {
          showRegion: 'inside',
          isBuildingMask: true,
          isPoiMask: true,
          isMapMask: true
        }
      );
      map.addOverlay(mapMask);
      specialOverlay.value.district.push({ name: toMask, overlay: mapMask });
      map.setViewport(points);
      
      // 在添加完覆盖物后立即更新面板
      if (showInfoPanel.value) // 确保面板是打开的
        updateDistrictList(); // 更新列表
    });
    return;
  }

  updateDistrictList();  // 重新生成列表
  console.log('Updated district list:', currentElement.value.district);
  console.log('Updated pending district list:', pendingDistricts.value);

  // 抽取更新列表的函数
  function updateDistrictList() {
    if (!specialOverlay?.value?.district) {
      specialOverlay.value = { district: [] };
    }

    const allDistricts = [
      // 常规区划
      ...currentElement.value.district.map(d => ({
        name: d.name,
        color: d.color,
        onColorChange: (newColor) => {
          const district = currentElement.value.district.find(item => item.name === d.name);
          if (district) {
            district.color = newColor;
          }
          const pendingDistrict = pendingDistricts.value.find(item => item.name === d.name);
          if (pendingDistrict) {
            pendingDistrict.color = newColor;
          }
        },
        onDelete: () => {
          currentElement.value.district = currentElement.value.district.filter(item => item.name !== d.name);
          pendingDistricts.value = pendingDistricts.value.filter(item => item.name !== d.name);
          updateDistrictList();
        }
      })),
      // 特殊区划（掩膜）
      ...specialOverlay.value.district.map(d => ({
        name: '@' + d.name, // 添加@前缀
        color: null, // 不提供颜色数据
        onColorChange: null, // 不提供颜色修改功能
        onDelete: () => {
          const district = specialOverlay.value.district.find(item => item.name === d.name);
          if (district && district.overlay) {
            map.removeOverlay(district.overlay);
          }
          specialOverlay.value.district = specialOverlay.value.district.filter(item => item.name !== d.name);
          updateDistrictList();
          showMessage(`已删除掩膜: ${d.name}`, 'success');
        }
      }))
    ];

    infoList.value = {
      type: 'districtList',
      data: allDistricts,
      title: {
        text: `当前显示行政区划 (${allDistricts.length} 个)`,
        detail: null
      }
    };

    if (allDistricts.length === 0) {
      showInfoPanel.value = false;
    }
  }
}