import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

export default function searchCommand(params) {
  const { map, BMapGL } = mapManager;
  const { showInfoPanel, infoList } = mapManager.getPanelControls();


  console.log('Executing search command with params:', params)
  if (params.length === 0) {
    showMessage('请输入搜索内容', 'warning')
    return
  }

  const keyword = params.join(' ')
  console.log(`Searching for: ${keyword}`)

  showInfoPanel.value = true
  infoList.value = { 
    type: 'search', 
    data: '',
    title: {
      text: `搜索: ${keyword}`,
      detail: null
    }
  }

  // 使用 Promise 和 setTimeout 来确保 DOM 已经更新
  return new Promise((resolve) => {
    setTimeout(() => {
      const panelElement = document.getElementById('info-panel-content')
      if (panelElement) {
        panelElement.innerHTML = ''
        console.log('Creating LocalSearch instance...');
        const localSearch = new BMapGL.LocalSearch(map, {
          renderOptions: { 
            map: map, 
            panel: panelElement  // 直接使用 DOM 元素
          }
        })
        
        console.log('LocalSearch instance created:', localSearch);
        localSearch.search(keyword)
        showMessage(`正在搜索: ${keyword}`, 'success')
        console.log('Returning LocalSearch instance from searchCommand');
        resolve(localSearch);
      } else {
        console.error('Failed to find info-panel-content element')
        showMessage('搜索面板初始化失败', 'error')
        resolve(null);
      }
    }, 100)
  });
}