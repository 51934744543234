import { showMessage } from '../utils/message'
import { unref } from 'vue'

// 提取获取热力图数据的函数
async function fetchHeatmapData(map, params) {
  const bounds = map.getBounds()
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  // 构建请求参数
  const queryParams = new URLSearchParams({
    mapname: params[0] || '顺丰速运',  // 默认使用'顺丰速运'
    lowlng: sw.lng-(ne.lng-sw.lng)/2,
    highlng: ne.lng+(ne.lng-sw.lng)/2,
    lowlat: sw.lat-(ne.lat-sw.lat)/2,
    highlat: ne.lat+(ne.lat-sw.lat)/2
  })

  const response = await fetch(`http://localhost:3000/heatmap?${queryParams}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const rawData = await response.json();

  console.log('Fetched heatmap data:', {
    mapname: params[0] || '顺丰速运',
    bounds: {
      sw: { lng: sw.lng, lat: sw.lat },
      ne: { lng: ne.lng, lat: ne.lat }
    },
    dataPoints: rawData.length,
    data: rawData
  });

  // 转换数据格式
  const maxCount = Math.max(...rawData.map(item => item.count));
  return {
    max: maxCount,
    data: rawData.map(item => ({
      lng: item.lng,
      lat: item.lat,
      count: item.count
    }))
  };
}

export default async function heatCommand(params, { BMap, mapInstance, showHeatmap, heatmapData }) {
  const map = unref(mapInstance)
  const bmap = unref(BMap)

  if (!map || !bmap) {
    showMessage('地图实例或BMap未初始化', 'error')
    return
  }

  try {
    // 初始加载热力图数据
    const formattedData = await fetchHeatmapData(map, params);
    
    // 更新热力图数据和显示状态
    heatmapData.value = formattedData;
    showHeatmap.value = true;

    showMessage('热力图数据已加载', 'success');

    // 添加地图事件监听器
    const updateHandler = async () => {
      try {
        const newData = await fetchHeatmapData(map, params);
        heatmapData.value = newData;
      } catch (error) {
        console.error('Error updating heatmap data:', error);
      }
    };

    // 监听地图移动和缩放事件
    map.addEventListener('moveend', updateHandler);
    map.addEventListener('zoomend', updateHandler);

    // 返回清理函数
    return () => {
      // 移除事件监听器
      map.removeEventListener('moveend', updateHandler);
      map.removeEventListener('zoomend', updateHandler);
      // 隐藏热力图
      showHeatmap.value = false;
      showMessage('热力图已关闭', 'info');
    };
  } catch (error) {
    console.error('Error loading heatmap data:', error);
    showMessage(`热力图数据加载失败: ${error.message}`, 'error');
    // 发生错误时隐藏热力图
    showHeatmap.value = false;
  }
}
