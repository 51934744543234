import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

export default function logoutCommand(params) {
  const { userRole, username, isStyleLoaded } = mapManager.getUserControls();
  const { currentElement, specialOverlay } = mapManager.getElementControls();
  const { showInfoPanel,closeInfoWindow } = mapManager.getPanelControls();

  if (params.length > 0) {
    showMessage('logout命令不需要参数', 'warning')
    return
  }

  // 检查当前是否已登录
  if (userRole.value === 'guest') {
    showMessage('您尚未登录', 'warning')
    return
  }

  try {
    // 重置用户状态
    const currentUsername = username.value  // 保存当前用户名用于显示消息
    userRole.value = 'guest'
    username.value = ''
    isStyleLoaded.value = false  // 清除样式加载状态

    for (const type in currentElement.value) {
        currentElement.value[type] = [];
      }

    //这里还缺清楚specialOverlay
      // 关闭信息面板
    showInfoPanel.value = false;
    closeInfoWindow();

    showMessage(`${currentUsername} 已退出登录`, 'success')
  } catch (error) {
    console.error('Logout error:', error)
    showMessage('退出登录失败', 'error')
  }
} 