import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'
import { API_ENDPOINTS } from '@/config/api'

export default async function lsCommand(params) {
  const { currentElement, globalStyle } = mapManager.getElementControls();
  const { showInfoPanel, infoList } = mapManager.getPanelControls();

  try {
    // 发送请求到后端
    const response = await fetch(API_ENDPOINTS.LIST, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const result = await response.json()
    console.log('List result:', result)

    // 更新 globalStyle 中的数量信息
    result.forEach(item => {
      if (globalStyle.value[item.style]) {
        globalStyle.value[item.style].num = item.num
      }
    })

    // 按 groupTag 组织数据
    const groupedData = {}
    let totalElements = 0

    Object.entries(globalStyle.value).forEach(([styleName, styleData]) => {
      const groupTag = styleData.groupTag || '其他'
      const seq = styleData.seq || 999
      const num = styleData.num || 0
      const title = styleData.label.title || styleName

      if (!groupedData[groupTag]) {
        groupedData[groupTag] = {
          items: [],
          seq: seq,
          total: 0
        }
      }

      groupedData[groupTag].items.push({
        style: title,
        num: num,
        seq: seq
      })
      groupedData[groupTag].total += num
      totalElements += num
    })

    // 排序并格式化数据
    const sortedGroups = Object.entries(groupedData)
      .sort(([,a], [,b]) => a.seq - b.seq)
      .reduce((acc, [tag, data]) => {
        acc[tag] = {
          items: data.items.sort((a, b) => a.seq - b.seq),
          total: data.total
        }
        return acc
      }, {})

    // 显示结果在 infoPanel 中
    showInfoPanel.value = true
    infoList.value = {
      type: 'styleList',  // 新的类型
      data: sortedGroups,
      title: {
        text: `地图元素统计 (${totalElements} 个)`,
        detail: null
      }
    }

    showMessage(`共统计 ${totalElements} 个元素`, 'success')
  } catch (error) {
    console.error('List error:', error)
    showMessage(`获取列表失败: ${error.message}`, 'error')
  }
} 