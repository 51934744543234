import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'
import { hasPermission } from '../config/permissions'
// 示例的 editPanel 命令
export default async function editPanelCommand(pid) {
  
  const {userRole} = mapManager.getUserControls();
  
  // 权限检查
  if (!hasPermission(userRole.value, 'editPanel', 'UI')) {
    showMessage('您没有权限执行此操作', 'error');
    return;
  }

  console.log("has permission to edit:", pid)
    // 查找对应的元素
    const { currentElement} = mapManager.getElementControls();
    const element = currentElement.value.marker.find(m => m.pid === pid) ||
    currentElement.value.polyline.find(p => p.pid === pid) ||
    currentElement.value.polygon.find(p => p.pid === pid);

    if (!element) {
    showMessage('未找到要编辑的元素', 'warning');
    return;
    }
    showEditDialog(element)

  // 获取要编辑的元素
  // const elementId = params[0]; // 假设第一个参数是要编辑的元素 ID
  // const element = currentElement.value.marker.find(m => m.pid === elementId) || 
  //                 currentElement.value.polyline.find(p => p.pid === elementId) || 
  //                 currentElement.value.polygon.find(p => p.pid === elementId);

  // if (!element) {
  //   showMessage('未找到要编辑的元素', 'warning');
  //   return;
  // }

  // // 弹出编辑窗口
  // const newDetail = await showEditDialog(element.detail); // 假设 showEditDialog 是一个弹出窗口函数

  // if (newDetail) {
  //   // 更新元素的 detail
  //   element.detail = newDetail;
  //   showMessage('元素已更新', 'success');

  //   // 这里可以添加保存到数据库的逻辑
  //   await saveElementToDatabase(element);
  // }
}

// 示例的弹出编辑窗口函数
async function showEditDialog(element) {
  const { showInfoPanel, infoList } = mapManager.getPanelControls();
  
  // 设置面板信息
  infoList.value = {
    type: 'editPanel',
    data: element.detail, // 传递 detail
    title: {text:element.title},
    pid: element.pid,
    style: element.style
  };
  console.log(infoList.value)
  showInfoPanel.value = true; // 显示面板
}

// 示例的保存到数据库的函数
export async function saveElementToDatabase(element) {
  console.log('got info:',element)
  // try {
  //   const response = await fetch('/api/saveElement', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(element)
  //   });

  //   if (!response.ok) {
  //     throw new Error('保存失败');
  //   }

  //   showMessage('元素已成功保存到数据库', 'success');
  // } catch (error) {
  //   console.error('保存到数据库时出错:', error);
  //   showMessage('保存到数据库失败', 'error');
  // }
}