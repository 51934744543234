import { showMessage } from '../utils/message'
import { useGeocoder } from 'vue-baidu-map-3x';
import { API_ENDPOINTS } from '@/config/api'
import mapManager from '../utils/mapManager'

export default async function queryCommand(params) {
  const { showInfoPanel,infoList } = mapManager.getPanelControls();
  const { lastTarget,globalStyle } = mapManager.getElementControls();
  const { searchInput } = mapManager.getInputControls();
  const map = mapManager.getMap();

  
  try {

    const queryParams = await parseQueryParams(params, {
      map:map,
      city: false,  // 只传递城市值
      globalStyle:globalStyle
    });
    if (!queryParams) {  // 添加检查
      throw new Error('Failed to parse query parameters')
    }else{
      console.log('queryParams got:', queryParams);
    }
    const requestParams = { ...queryParams }
    console.log('Sending query to:', API_ENDPOINTS.QUERY)
 

    const response = await fetch(API_ENDPOINTS.QUERY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestParams)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Query result:', result);

    // 构建查询条件显示文本
    const conditions = [];
    if (queryParams.title) {
      conditions.push(`标题包含"${queryParams.title.replace(/%/g, '')}"`);
    }
    if (queryParams.city) {
      if (Array.isArray(queryParams.city)) {
        conditions.push(`城市为${queryParams.city.join('/')}`);
      } else {
        conditions.push(`城市为${queryParams.city}`);
      }
    }
    const groupCondition = params.find(param => !param.includes('='));
    if (groupCondition) {
      conditions.push(`分组为${groupCondition}`);
    }

    infoList.value = { 
      type: 'list', 
      data: result.map(item => ({ 
        content: item.title, 
        style: { color: 'black' },
        type: getElementType(item.shapeData)
      })),
      title: {
        text: `查询条件 (${result.length} Results)`,
        detail: conditions.length > 0 ? conditions.join('，') : '无限制条件'
      }
    };
    lastTarget.value = result.map(item => ({
      pid: item.pid,
      title: item.title,
      style: item.style,
      shapeData: JSON.parse(item.shapeData),
      detail: JSON.parse(item.detail)
    }));
    // console.log('lastTarget:', lastTarget.value);
    // console.log('infoList:', infoList.value);
    showInfoPanel.value = true;
    showMessage('查询结果已显示', 'success', searchInput);
  } catch (error) {
    console.error('Query error:', error)
    console.error('Using endpoint:', API_ENDPOINTS.QUERY)
    showMessage(`查询失败: ${error.message}`, 'error', searchInput);
  }
}

async function parseQueryParams(params, { map, city, globalStyle }) {
  const queryParams = {};

  try {
      for (const param of params) {
      const [key, value] = param.split('=');
      if (key && value) {
        if (key.toLowerCase() === 'city') {
          queryParams.city = value;
        } else if (key.toLowerCase() === 'title') {
          queryParams[key] = `%${value}%`;
        } 
      }else if (key){
        
        const matchedStyles = findMatchingStyles(key, globalStyle);
        // console.log('key and matchedStyles:', key, matchedStyles);
        if (matchedStyles.length > 0) {
          queryParams['style'] = matchedStyles;
        }
      }
    }

    // 处理 city 参数的逻辑保持不变
    if (city === 'all') {
      // 不对 city 进行限制
    } else if (city && /[\/、\.\|]/.test(city)) {
      queryParams.city = city.split(/[\/、\.\|]/);
    } else if (!city) {
      // 使用 Promise 来等待地理编码结果
      const geocoder = await useGeocoder();
      const result = await new Promise((resolve) => {
        geocoder.getLocation(map.getCenter(), resolve);
      });
      
      const nowCity = result.addressComponents.city;
      queryParams.city = nowCity.endsWith('市') ? nowCity.slice(0, -1) : nowCity;
    
    } else {
      queryParams.city = "all";
    }

    return {...queryParams};

  }catch (error) {
    console.error('Error in parseQueryParams:', error)
    throw error
  }
  // console.log('queryParams:', queryParams);
  return {...queryParams};
}

function findMatchingStyles(searchValue, globalStyle) {
  const styleNames = new Set();  // 使用 Set 来自动去重
  // console.log('globalStyle:', globalStyle.value);
  if (!globalStyle || !globalStyle.value) return Array.from(styleNames);

  // 转换搜索值为小写，用于不区分大小写的比较
  const searchLower = searchValue.toLowerCase();

  // 先找到所有匹配 groupTag 的组
  const matchedGroups = new Set();
  Object.entries(globalStyle.value).forEach(([_, style]) => {
    // console.log('style?:', style.groupTag,style.groupTag.toLowerCase().includes(searchLower));

    if (style.groupTag && style.groupTag.toLowerCase().includes(searchLower)) {
      matchedGroups.add(style.groupTag);
    }
  });

  // 遍历所有样式
  Object.entries(globalStyle.value).forEach(([styleName, style]) => {
    // 如果样式的 groupTag 在匹配组中，添加该样式名
    if (style.groupTag && matchedGroups.has(style.groupTag)) {
      styleNames.add(styleName);
    }
    // 如果样式的 title 匹配，也添加该样式名
    else if (style.label && style.label.title && 
        style.label.title.toLowerCase().includes(searchLower)) {
      styleNames.add(styleName);
    }
  });

  return Array.from(styleNames);
}

function getElementType(shapeData) {
  const shape = JSON.parse(shapeData);
  return shape.type || 'unknown';
}