// 权限等级
export const ROLES = {
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager'
}

// 命令权限映射
export const COMMAND_PERMISSIONS = {
  // 基础命令 - guest可用
  login: [ROLES.GUEST, ROLES.USER, ROLES.MANAGER],
  logout: [ROLES.USER, ROLES.MANAGER],
  search: [ROLES.GUEST, ROLES.USER, ROLES.MANAGER],
  district: [ROLES.GUEST, ROLES.USER, ROLES.MANAGER],
  measure: [ROLES.GUEST, ROLES.USER, ROLES.MANAGER],
  go: [ROLES.GUEST, ROLES.USER, ROLES.MANAGER],
  help: [ROLES.GUEST, ROLES.USER, ROLES.MANAGER],
  // 数据查看命令 - user可用
  query: [ROLES.USER, ROLES.MANAGER],
  show: [ROLES.USER, ROLES.MANAGER],
  list: [ROLES.USER, ROLES.MANAGER],
  hide: [ROLES.USER, ROLES.MANAGER],
  heat: [ROLES.USER, ROLES.MANAGER],

  // 数据修改命令 - manager可用
  draw: [ROLES.MANAGER],
  edit: [ROLES.MANAGER],
  delete: [ROLES.MANAGER]
}

// 界面元素权限映射
export const UI_PERMISSIONS = {
  editPanel: [ROLES.MANAGER],
  rightClickMenu: [ROLES.GUEST,ROLES.MANAGER],
}

// 权限检查函数
export function hasPermission(userRole, action, type = 'command') {
  console.log('Checking permission:', { userRole, action, type });
  const permissionMap = type === 'command' ? COMMAND_PERMISSIONS : UI_PERMISSIONS;
  console.log('permissionMap', permissionMap)
  console.log('try', permissionMap[action])
  const hasAccess = permissionMap[action]?.includes(userRole) || false;
  console.log('Permission result:', hasAccess);
  return hasAccess;
} 