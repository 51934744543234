import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

export default function goCommand(params) {
  const { map, BMapGL } = mapManager;
  const { hideInput } = mapManager.getInputControls();

  if (!map || !BMapGL) {
    showMessage('地图实例未初始化', 'error')
    return
  }

  if (params.length === 0 || params.length > 2) {
    showMessage('go命令参数错误', 'warning')
    return
  }

  const location = params[0]
  let zoom = null

  // 处理可选的 zoom 参数
  if (params.length === 2) {
    const zoomLevel = parseInt(params[1])
    if (isNaN(zoomLevel) || zoomLevel < 5 || zoomLevel > 18) {
      showMessage('缩放级别必须是5-18之间的数字', 'warning')
      return
    }
    zoom = zoomLevel
  }

  // console.log('location:', location);
  map.centerAndZoom(location, zoom || map.getZoom()) 

  // // 使用地理编码服务
  // const geocoder = new bmap.Geocoder()
  // geocoder.getPoint(location, point => {
  //   if (point) {
  //     map.centerAndZoom(point, zoom || map.getZoom())  // 使用指定的 zoom 或保持当前 zoom
  //     showMessage(`已定位到: ${location}`, 'success')
  //   } else {
  //     showMessage(`未找到位置: ${location}`, 'error')
  //   }
  // })
}