import { ElMessage } from 'element-plus'

export function showMessage(message, type = 'info', duration = 3000) {
  ElMessage({
    message,
    type,
    duration,
    showClose: duration === 0, // 如果 duration 为 0，显示关闭按钮
  })
}
