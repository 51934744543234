import { showMessage } from './message'
import mapManager from './mapManager'

export async function handleInput({ 
  placeholder = '请输入...', 
  isPassword = false 
}) {
  const { showInput, hideInput } = mapManager.getInputControls();
  // 确保输入框可见
  await new Promise((resolve) => {
    showInput(); // 调用 showInput
    resolve(); // 立即解决 Promise
  });

  return new Promise((resolve, reject) => {
    // isInputVisible.value = true;

    const { searchInput } = mapManager.getInputControls();  
    console.log('searchInput:', searchInput.value);
    
    // 获取实际的输入元素
    const inputElement = searchInput.value.inputRef.input; // 访问 inputRef 的 value.input
    
    console.log('inputElement:', inputElement);

    if (inputElement) {
      console.log("Input element found, setting properties...");
      // 设置输入框属性
      inputElement.type = isPassword ? 'password' : 'text';
      inputElement.placeholder = placeholder;
      inputElement.value = ''; // 清空输入框
      inputElement.focus(); // 确保输入框获得焦点
      
      const handleKeydown = async (event) => {
        console.log("Handling keydown event...");
        if (event.key === 'Enter') {
          console.log("Enter key pressed");
          event.preventDefault();
          const value = inputElement.value.trim();
          console.log("Captured value:", value);
          
          if (value) {
            // 清理
            inputElement.removeEventListener('keydown', handleKeydown);
            inputElement.type = 'text'; // 恢复输入框类型
            inputElement.placeholder = '请输入命令';
            hideInput();
            resolve(value);
          }
        } else if (event.key === 'Escape') {
          console.log("Escape key pressed");
          // 用户取消
          inputElement.removeEventListener('keydown', handleKeydown);
          inputElement.type = 'text';
          inputElement.placeholder = '请输入命令';
          hideInput();
          reject({ message: '用户取消输入' }); // 返回对象
        }
      };

      inputElement.addEventListener('keydown', handleKeydown);
      console.log("Keydown event listener added.");
    } else {
      showMessage('无法找到输入框元素', 'error');
      reject(new Error('无法找到输入框元素'));
    }
  });
} 