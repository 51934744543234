import { showMessage } from '../utils/message'
import { handleInput } from '../utils/inputHandler'
import mapManager from '../utils/mapManager'
// import { DrawScene, PolylineDraw } from 'bmap-draw';


// 简化后的 BMapDraw 加载函数
async function waitForBMapDraw() {
  if (window.BMapDraw) {
    return window.BMapDraw;
  }

  try {
    console.log('Loading bmap-draw...');
    const module = await import('bmap-draw');
    window.BMapDraw = module.default || module;
    console.log('bmap-draw loaded successfully');
    return window.BMapDraw;
  } catch (error) {
    console.error('Failed to load bmap-draw:', error);
    showMessage("无法加载测量组件","error")
    throw error;
  }
}


export default async function drawCommand(params) {
  const { map, BMapGL } = mapManager;
  const { currentElement, calculatePointsCenter } = mapManager.getElementControls();
  // const { hideInput } = mapManager.getInputControls();

  if (!map || !BMapGL) {
    showMessage('地图实例未初始化', 'error')
    return
  }

  const [type, position] = params
  console.log('Draw command params:', { type, position });
  
  try{
    await waitForBMapDraw();
    
    const { DrawScene,DistanceMeasure, AreaMeasure} = window.BMapDraw;
    
    const scene = new DrawScene(map)
    let drawingPromise; // 用于存储绘制的 Promise
      // 处理 marker 类型
      if (type === 'marker' && position) {
        drawingPromise = new Promise((resolve) => {
          const [lng, lat] = position.split(',').map(Number);
          const point = new BMapGL.Point(lng, lat);
          const marker = new BMapGL.Marker(point);
          map.addOverlay(marker);
          resolve({target:{overlay:marker,points:[point]}}); // 完成后解析 Promise
        });

      } else if ( type === 'polyline' ){
          console.log('start to draw polyline')
          // const scene = new DrawScene(map);
          drawingPromise = new Promise((resolve) => {
            const polyline = new DistanceMeasure(scene, {
              isSeries: false
            })
      
            polyline.addEventListener('measure-length-end', e => {
              console.log('绘制polyline结束', e);
              resolve(e)
            });
          });

      } else if (type === 'polygon'){
        console.log('start to draw polygon')
          drawingPromise = new Promise((resolve) => {
            const polygon = new AreaMeasure(scene, {
              isSeries: false,
              baseOpts: {
                fillColor: '#FF0000',
                fillOpacity: 0.1,
                strokeColor: '#FF0000',
                showlabel: false,
              },
            })
      
            polygon.addEventListener('measure-area-end', e => {
              console.log('绘制polygon结束', e);
              resolve(e); // 在绘制完成时解析 Promise
            });
         
          });
      }





    // 等待绘制完成
    const newOverlay = await drawingPromise;
    console.log(newOverlay)

    //输入元素名称
    const title = await handleInput({
      placeholder: '请输入该元素的名称'
    });
    
    if (!currentElement.value[type]) {
      currentElement.value[type] = [];
    }
    
    currentElement.value[type].push({
       pid:'unsaved', 
       points:newOverlay.target.points, 
       style:'others', 
       title:title,
       detail:{},
       showLabel: true,
       labelPosition:calculatePointsCenter(newOverlay.target.points)
    });
    
    map.clearOverlays()
   
    //
    // if (!specialOverlay.value.unsubmitted) {
    //   specialOverlay.value.unsubmitted = [];
    // }
    // console.log(specialOverlay)
    // specialOverlay.value.unsubmitted.push({
    //   pid: Date.now().toString(),
    //   points:newOverlay.target.points,
    //   type: type,
    //   overlay: newOverlay.target.overlay,
    //   title: title,
    // });
    showMessage(`标记 "${title}" 已添加`, 'success');
    console.log('currentElement:',currentElement)
    
  }catch(error) {

    if (error.message === '用户取消输入') {
      console.log('用户取消了输入，已绘制的图像不作保存');
      map.clearOverlays()

    } else {
      console.error('绘制时发生错误:', error);
      showMessage('绘制失败', 'error');
    }
  }



  
}