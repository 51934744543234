import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

// 简化后的 BMapDraw 加载函数
async function waitForBMapDraw() {
  if (window.BMapDraw) {
    return window.BMapDraw;
  }

  try {
    console.log('Loading bmap-draw...');
    const module = await import('bmap-draw');
    window.BMapDraw = module.default || module;
    console.log('bmap-draw loaded successfully');
    return window.BMapDraw;
  } catch (error) {
    console.error('Failed to load bmap-draw:', error);
    showMessage("无法加载测量组件","error")
    throw error;
  }
}

export default async function measureCommand(params) {
  const map = mapManager.getMap();
  const { hideInput } = mapManager.getInputControls();

  let measureType = 'line';  // 默认为 line
  if (params.length > 0) {
    const type = params[0].toLowerCase();
    if (type === 'l' || type === 'line') {
      measureType = 'line';
    } else if (type === 'p' || type === 'polygon' || type === 'polygo') {
      measureType = 'polygon';
    } else {
      showMessage('无效的测量类型，使用默认类型: line', 'warning');
    }
  }
  
  hideInput();

  try {
    await waitForBMapDraw();
    console.log(measureType)
    const { DrawScene, DistanceMeasure, AreaMeasure } = window.BMapDraw;
    
    const scene = new DrawScene(map)

    let measureTool;
    if (measureType === 'line') {
      measureTool = new DistanceMeasure(scene, {
        isSeries: false
      })

      measureTool.addEventListener('measure-length-end', e => {
        console.log('测量结束', e);
      });

      showMessage('请在地图上点击开始测量距离，双击结束测量', 'info')
    } else if (measureType === 'polygon') {
      measureTool = new AreaMeasure(scene, {
        isSeries: false,
        unit:'metric',
        baseOpts: {
          fillColor: '#FF0000',
          fillOpacity: 0.1,
          strokeColor: '#FF0000',
        },
      })

      measureTool.addEventListener('measure-area-end', e => {
        console.log('测量结束', e);
      });

      showMessage('请在地图上点击绘制多边形，双击结束测量', 'info')
    }

    measureTool.open()

    return () => {
      if (measureType === 'line') {
        measureTool.removeEventListener('measure-length-end');
      } else if (measureType === 'polygon') {
        measureTool.removeEventListener('measure-area-end');
      }
      measureTool.close()
      scene.clearData()
    }
  } catch (error) {
    showMessage(`测量初始化失败: ${error.message}`, 'error')
  }
}
