// 使用相对协议，让 IIS 处理转发
export const API_BASE_URL = import.meta.env.MODE === 'production' 
  // ? `${window.location.protocol}//${window.location.hostname}/api`  // 通过 IIS 转发
  ? 'https://x.jhana.xyz:3001'  // 直连3001
  : 'http://localhost:3000';                                       // 开发环境直连

export const API_ENDPOINTS = {
  TEST_DB: `${API_BASE_URL}/test-db`,
  GLOBAL_STYLE: `${API_BASE_URL}/globalStyle`,
  QUERY: `${API_BASE_URL}/query`,
  HEATMAP: `${API_BASE_URL}/heatmap`,
  LIST: `${API_BASE_URL}/list`,
  LOGIN: `${API_BASE_URL}/login`
};

// 输出所有端点
console.log('API_ENDPOINTS:', API_ENDPOINTS);