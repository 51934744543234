import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

// 提取 now 参数的处理逻辑为单独的函数
function showCategorizedView() {
  const { currentElement, globalStyle } = mapManager.getElementControls();
  const { showInfoPanel, infoList ,subGroupLabels} = mapManager.getPanelControls();

  // 确保使用最新的 currentElement 数据
  const elements = [
    ...(currentElement.value.marker || []).map(e => ({ ...e, type: 'marker', showLabel: true })),
    ...(currentElement.value.polyline || []).map(e => ({ ...e, type: 'polyline', showLabel: true })),
    ...(currentElement.value.polygon || []).map(e => ({ ...e, type: 'polygon', showLabel: true }))
  ];

  // 如果没有任何元素，只显示提示消息
  if (elements.length === 0) {
    showMessage('当前页面没有可显示的元素', 'warning');
    return;
  }
  
  // 按大类（groupTag）和小类（title）分组
  const groupedElements = elements.reduce((acc, element) => {
    const styleObj = globalStyle.value[element.style] || {};
    const groupTag = styleObj.groupTag || 'Other';
    if (!acc[groupTag]) {
      acc[groupTag] = {};
    }
    const subGroup = styleObj.label?.title || 'Other';
    if (!acc[groupTag][subGroup]) {
      acc[groupTag][subGroup] = [];
    }
    acc[groupTag][subGroup].push({
      ...element,
      seq: styleObj.seq || 'Z999' // 添加 seq 信息，默认值确保未定义时排在最后
    });
    return acc;
  }, {});

  // 对 groupTags 进行排序
  const sortedGroups = Object.entries(groupedElements).sort((a, b) => {
    // 找到每个组中最小的 seq
    const aSeqs = Object.values(a[1]).flatMap(items => items.map(item => item.seq));
    const bSeqs = Object.values(b[1]).flatMap(items => items.map(item => item.seq));
    const aMinSeq = aSeqs.length > 0 ? aSeqs.reduce((min, seq) => seq < min ? seq : min) : 'Z999';
    const bMinSeq = bSeqs.length > 0 ? bSeqs.reduce((min, seq) => seq < min ? seq : min) : 'Z999';
    return aMinSeq < bMinSeq ? -1 : aMinSeq > bMinSeq ? 1 : 0;
  });

  // 创建新的排序后的对象
  const sortedGroupedElements = {};
  for (const [groupTag, subGroups] of sortedGroups) {
    sortedGroupedElements[groupTag] = {};
    
    // 对每个组内的 subGroups 进行排序
    const sortedSubGroups = Object.entries(subGroups).sort((a, b) => {
      // 找到每个子组中最小的 seq
      const aSeqs = a[1].map(item => item.seq);
      const bSeqs = b[1].map(item => item.seq);
      const aMinSeq = aSeqs.length > 0 ? aSeqs.reduce((min, seq) => seq < min ? seq : min) : 'Z999';
      const bMinSeq = bSeqs.length > 0 ? bSeqs.reduce((min, seq) => seq < min ? seq : min) : 'Z999';
      return aMinSeq < bMinSeq ? -1 : aMinSeq > bMinSeq ? 1 : 0;
    });

    // 将排序后的 subGroups 添加到结果对象中
    for (const [subGroupTitle, items] of sortedSubGroups) {
      // 移除临时添加的 seq 字段
      sortedGroupedElements[groupTag][subGroupTitle] = items.map(item => {
        const { seq, ...rest } = item;
        return rest;
      });
    }
  }

  // 初始化 subGroupLabels
  if (subGroupLabels && subGroupLabels.value) {
    Object.keys(sortedGroupedElements).forEach(groupTag => {
      subGroupLabels.value[groupTag] = {};
      Object.keys(sortedGroupedElements[groupTag]).forEach(subGroupTitle => {
        subGroupLabels.value[groupTag][subGroupTitle] = true;
      });
    });
    console.log('Initialized subGroupLabels:', subGroupLabels.value);
  } else {
    console.warn('subGroupLabels is not available');
  }

  showInfoPanel.value = true;
  infoList.value = {
    type: 'categorized',
    data: sortedGroupedElements,
    title: {
      text: '当前页面元素',
      detail: null  // 分类视图不需要详细信息
    }
  };
  
  console.log('Showing categorized view:', sortedGroupedElements);
  showMessage('分类视图已显示', 'success');
}

export default async function showCommand(params) {
  const { currentElement, globalStyle ,lastTarget} = mapManager.getElementControls();
  const { showInfoPanel, infoList ,subGroupLabels} = mapManager.getPanelControls();

  if (!globalStyle.value) {
    showMessage('Global styles are not loaded yet', 'error');
    return;
  }

  if (params.length === 0) {
    // 无参数时的操作
    lastTarget.value.forEach(item => {
      const { pid, shapeData, style, title, detail } = item;
      const { type, points, labelPosition } = shapeData;

      if (!currentElement.value[type]) {
        currentElement.value[type] = [];
      }

      if (!currentElement.value[type].some(element => element.pid === pid)) {
        currentElement.value[type].push({ pid, points, style, title, detail, showLabel: true, labelPosition });
      }
    });

    console.log('currentElement:', JSON.parse(JSON.stringify(currentElement.value)));
    showMessage('地图元素已显示', 'success');

    // 在无参数处理后也显示分类视图
    showCategorizedView({ currentElement, globalStyle, showInfoPanel, infoList, subGroupLabels });
  } else if (params[0] === 'now') {
    // 直接调用分类视图显示函数
    showCategorizedView({ currentElement, globalStyle, showInfoPanel, infoList, subGroupLabels });
  } else {
    // 处理其他参数的情况
    showMessage(`未知的 show 命令参数: ${params.join(' ')}`, 'warning');
  }
}