import goCommand from './commands/go';
import districtCommand from './commands/district';
import queryCommand from './commands/query';
import drawCommand from './commands/draw';
import searchCommand from './commands/search';
import showCommand from './commands/show';
import hideCommand from './commands/hide';
import measureCommand from './commands/measure';
import heatCommand from './commands/heat';
import helpCommand from './commands/help';
import lsCommand from './commands/ls';
import loginCommand from './commands/login';
import logoutCommand from './commands/logout'
import { hasPermission } from './config/permissions'
import { showMessage } from './utils/message'
import { ElLoading } from 'element-plus'
import mapManager from './utils/mapManager'

const commandMap = {
  go: goCommand,
  g: goCommand,
  district: districtCommand,
  d: districtCommand,
  query: queryCommand,
  q: queryCommand,
  draw: drawCommand,
  dr: drawCommand,
  search: searchCommand,
  s: searchCommand,
  show: showCommand,
  hide: hideCommand,
  measure: measureCommand,
  m: measureCommand,
  heat: heatCommand,
  he: heatCommand,
  help: helpCommand,
  h: helpCommand,
  ls: lsCommand,
  list: lsCommand,
  login: loginCommand,
  logout: logoutCommand,
  '?': helpCommand,
  
  
};

// 命令缩写映射
const COMMAND_ALIASES = {
  's': 'search',
  'q': 'query',
  'ls': 'list',
  'h': 'hide',
  'm': 'measure',
  'd': 'district',
  'g': 'go'
  // ... 其他缩写映射
}

export async function parseCommand(commandString, context) {
  const loading = ElLoading.service({
    lock: true,
    text: '命令执行中...',
    background: 'rgba(255, 255, 255, 0.7)'
  })

  try {
    const [command, ...params] = commandString.split(' ')
    let lowercaseCommand = command.toLowerCase()
    lowercaseCommand = COMMAND_ALIASES[lowercaseCommand] || lowercaseCommand

    if (!hasPermission(context.userRole.value, lowercaseCommand)) {
      showMessage('您没有权限执行此命令', 'error')
      return
    }

    // 创建简化的上下文对象
    const commandContext = {
      ...context,
      map: mapManager.getMap(),      // 直接使用的地图实例
      BMapGL: mapManager.getBMap(),    // 直接使用的BMap实例
    }

    if (commandMap[lowercaseCommand]) {
      return commandMap[lowercaseCommand](params, commandContext)
    } else {
      return queryCommand([commandString], commandContext)
    }
  } catch (error) {
    console.error('Command execution error:', error)
    showMessage('命令执行出错', 'error')
  } finally {
    loading.close()
  }
}
