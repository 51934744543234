// 全局状态管理器
class MapManager {
  constructor() {
    // 地图相关
    this.BMapGL = null
    this.map = null

    // 输入控制相关
    this.searchInput = null
    this.isInputVisible = null
    this.showInput = null
    this.hideInput = null
    this.isPasswordMode = null

    // 面板控制相关
    this.showInfoPanel = null
    this.infoList = null
    this.closeInfoWindow = null
    this.subGroupLabels = null

    // 元素管理相关
    this.currentElement = null
    this.globalStyle = null
    this.specialOverlay = null
    this.pendingDistricts = null
    this.lastTarget = null

    // 用户状态相关
    this.userRole = null
    this.username = null
    this.isStyleLoaded = null
    this.loadGlobalStyle = null
  }

  // 初始化所有全局变量
  initialize({
    BMapInstance, 
    map,
    searchInput,
    isInputVisible,
    showInput,
    hideInput,
    setPasswordMode,
    showInfoPanel,
    infoList,
    closeInfoWindow,
    subGroupLabels,
    currentElement,
    globalStyle,
    specialOverlay,
    pendingDistricts,
    lastTarget,
    userRole,
    username,
    isStyleLoaded,
    loadGlobalStyle,
    calculatePointsCenter
  }) {
    this.BMapGL = BMapInstance;
    this.map = map;
    this.searchInput = searchInput;
    this.isInputVisible = isInputVisible;
    this.showInput = showInput;
    this.hideInput = hideInput;
    this.setPasswordMode = setPasswordMode;
    this.showInfoPanel = showInfoPanel;
    this.infoList = infoList;
    this.closeInfoWindow = closeInfoWindow;
    this.subGroupLabels = subGroupLabels;
    this.currentElement = currentElement;
    this.globalStyle = globalStyle;
    this.specialOverlay = specialOverlay;
    this.pendingDistricts = pendingDistricts;
    this.lastTarget = lastTarget;
    this.userRole = userRole;
    this.username = username;
    this.isStyleLoaded = isStyleLoaded;
    this.loadGlobalStyle = loadGlobalStyle;
    this.calculatePointsCenter = calculatePointsCenter;
  }

  // 获取地图实例
  getMap() {
    return this.map
  }

  // 获取BMap实例
  getBMap() {
    return this.BMapGL
  }

  // 获取输入控制相关变量
  getInputControls() {
    return {
      searchInput: this.searchInput,
      isInputVisible: this.isInputVisible,
      showInput: this.showInput,
      hideInput: this.hideInput,
      setPasswordMode: this.setPasswordMode
    }
  }

  // 获取面板控制相关变量
  getPanelControls() {
    return {
      showInfoPanel: this.showInfoPanel,
      infoList: this.infoList,
      closeInfoWindow: this.closeInfoWindow,
      subGroupLabels: this.subGroupLabels
    }
  }

  // 获取元素管理相关变量
  getElementControls() {
    return {
      currentElement: this.currentElement,
      globalStyle: this.globalStyle,
      specialOverlay: this.specialOverlay,
      pendingDistricts: this.pendingDistricts,
      lastTarget: this.lastTarget,
      calculatePointsCenter: this.calculatePointsCenter
    }
  }

  // 获取用户状态相关变量
  getUserControls() {
    return {
      userRole: this.userRole,
      username: this.username,
      isStyleLoaded: this.isStyleLoaded,
      loadGlobalStyle: this.loadGlobalStyle
    }
  }
}

// 导出单例
export default new MapManager() 