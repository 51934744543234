import { handleInput } from '../utils/inputHandler'
import { API_ENDPOINTS } from '@/config/api'
import { showMessage } from '@/utils/message'
import mapManager from '../utils/mapManager'

// SHA-256 加密函数
async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export default async function loginCommand(params,{testDB}) {
  const { userRole, username, isStyleLoaded, loadGlobalStyle } = mapManager.getUserControls();
  // const { searchInput, isInputVisible, hideInput,setPasswordMode } = mapManager.getInputControls();

  if (params.length > 0) {
    showMessage('login命令不需要参数', 'warning')
    return
  }

  // 先测试数据库连接
  try {
    await testDB()
  } catch (error) {
    showMessage('数据库连接失败', 'error')
    return
  }

  // 设置密码模式
  
  
  try {
    // setPasswordMode(true)
    const password = await sha256(await handleInput({
      placeholder: '请输入密码',
      isPassword: true
    }));
    
    console.log(password)
    const response = await fetch(API_ENDPOINTS.LOGIN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password })
    })

    if (!response.ok) throw new Error('Login failed')
    
    const data = await response.json()
    console.log('Login response:', data)
    if (data.success) {
      userRole.value = data.permission
      username.value = data.username
      showMessage(`登录成功: ${data.username}`, 'success')
      
      // 加载全局样式
      if (!isStyleLoaded.value) {
        try {
          await loadGlobalStyle()
          isStyleLoaded.value = true
        } catch (error) {
          console.error('Failed to load global style:', error)
          showMessage('加载全局样式失败', 'error')
        }
      }
    } else {
      showMessage('密码错误或已过期', 'error')
    }
  } catch (error) {
    console.error('Login error:', error)
    showMessage('登录失败', 'error')
  } finally {
    // setPasswordMode(false)  // 确保退出密码模式
  }
} 