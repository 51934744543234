import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import BaiduMap from 'vue-baidu-map-3x'

console.log('Starting app initialization...');

// 先创建一个空的 BMapGL 对象
// window.BMapGL = {};

const app = createApp(App)

app.use(ElementPlus)
console.log('Element Plus initialized');

// 初始化百度地图
console.log('Before BaiduMap initialization');
app.use(BaiduMap, {
  ak: 'zzuaxidnVpyre5181dA4C5hG',
  type: 'WebGL',
  plugins: ['Heatmap']
});
console.log('After BaiduMap initialization');

app.mount('#app')
console.log('App mounted');
