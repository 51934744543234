export const commandHints = {
  help: {
    command: 'help',
    aliases: ['h', '?'],
    description: '显示命令帮助',
    params: [],
    examples: ['help', 'h', '?'],
    usage: 'help'
  },
  
  go: {
    command: 'go',
    aliases: ['g'],
    description: '移动到指定位置',
    params: [
      {
        name: '<地点名称>',
        description: '目标位置名称，城市名或地点名皆可，会匹配最近的地点',
        required: true
      },
      {
        name: '[缩放级别]',
        description: '可选缩放级别，5-18之间的数字，默认会根据地点名称自动调整缩放级别',
        required: false
      }
    ],
    examples: ['go 上海 15', 'g 人民广场'],
    usage: 'go <地点名称> [缩放级别]'
  },

  district: {
    command: 'district',
    aliases: ['d'],
    description: '显示指定的行政区划',
    params: [
      {
        name: '[+|-]<行政区划名>|all',
        description: '指定行政区划，支持多个(用逗号顿号/分隔)，+表示显示，-表示隐藏，默认+。-all表示隐藏所有在显示的行政区划',
        required: true
      },
      {
        name: '[颜色]',
        description: '指定行政区划颜色，支持RGB或英文颜色名，默认红色',
        required: false
      },{
        name: '[透明度]',
        description: '指定行政区划透明度，0-1之间，默认0.2',
        required: false
      }
    ],
    examples: ['district 上海、北京', 'd 黄浦区/静安区 green 0.5','d -黄浦区','d -all'],
    usage: 'district [+|-]<行政区划名> [颜色] [透明度]'
  },

  draw: {
    command: 'draw',
    aliases: ['dr'],
    description: '绘制图形。功能待完善',
    params: [
      {
        name: 'line',
        description: '绘制线条',
        required: true
      }
    ],
    examples: ['draw line', 'dr line'],
    usage: 'draw line'
  },

  search: {
    command: 'search',
    aliases: ['s'],
    description: '搜索地点',
    params: [
      {
        name: '<关键词>',
        description: '按关键词模糊查询，将打开搜索结果面板并定位到第一个结果',
        required: true
      }
    ],
    examples: ['search 人民广场', 's 中山路'],
    usage: 'search <关键词>'
  },

  query: {
    command: 'query',
    aliases: ['q'],
    description: '查询数据库信息，会显示查询结果列表，再通过show命令显示到地图上',
    params: [
      {
        name: '[city=<城市名|all>]',
        description: '指定城市范围，支持多城市(用顿号或竖线分隔)，all表示所有城市。不指定城市则默认查询当前城市',
        required: false,
        default: '当前城市'
      },
      {
        name: '[title=<关键词>]',
        description: '按title模糊查询',
        required: false
      },
      {
        name: '[[group=]<分组名称>]',
        description: '按groupTag或subGroupTag模糊查询',
        required: false
      }
    ],
    examples: ['query city=上海、北京', 'q title=广场', 'q group=城市更新','q title=栖霞 产业园'],
    usage: 'query [city=<城市名|all>] [title=<关键词>] [[group=]<分组名称>]'
  },

  measure: {
    command: 'measure',
    aliases: ['m'],
    description: '测量距离或面积',
    params: [
      {
        name: 'line | polygon',
        description: 'line或l或无参数：测量距离, polygon或p：测量面积。默认是line',
        required: false,
        default: 'line'
      }
    ],
    examples: ['measure line', 'm', 'measure polygon', 'm p'],
    usage: 'measure [l|line|p|polygon]'
  },

  heat: {
    command: 'heat',
    aliases: ['he'],
    description: '显示热力图，尚未完工',
    params: [
      {
        name: '[地图名称]',
        description: '指定热力图数据源',
        required: false,
        default: '顺丰速运'
      }
    ],
    examples: ['heat', 'he 顺丰速运'],
    usage: 'heat [地图名称]'
  },

  show: {
    command: 'show',
    aliases: [],
    description: '显示地图元素',
    params: [
      {
        name: '无参数',
        description: '无参数时，将最近query查询结果显示到地图上',
        required: false
      },
      {
        name: 'now',
        description: '显示当前页面上存在元素的结构视图',
        required: false
      }
    ],
    examples: ['show', 'show now'],
    usage: 'show [now]'
  },

  hide: {
    command: 'hide',
    aliases: [],
    description: '隐藏部分或全部的地图元素',
    params: [
      {
        name: 'all',
        description: '隐藏所有元素',
        required: false
      }
    ],
    examples: ['hide', 'hide all'],
    usage: 'hide [all]'
  },
  ls: {
    command: 'ls',
    aliases: ['list'],
    description: '列出当前数据库元素结构',
    params: [],
    examples: ['ls','list'],
    usage: 'ls'
  }
};

// 辅助函数：根据输入内容获取提示
export function getHints(input) {
  // 如果是空输入，不显示任何提示
  if (!input) {
    return [];
  }

  const [command, ...params] = input.toLowerCase().split(' ');

  // 如果输入的是 help 相关命令，显示所有命令的简略格式
  if (command === 'help' || command === 'h' || command === '?') {
    return Object.values(commandHints).map(hint => ({
      value: hint.command,
      command: hint.command + (hint.aliases.length ? ` (${hint.aliases.join(', ')})` : ''),
      description: hint.description,
      type: 'brief'
    }));
  }

  // 查找匹配的命令
  const matchedCommands = Object.values(commandHints).filter(hint => {
    // 如果有参数，只匹配完全相等的命令或别名
    if (params.length > 0) {
      return hint.command === command || hint.aliases.includes(command);
    }
    // 否则匹配前缀
    return hint.command.startsWith(command) || 
           hint.aliases.some(alias => alias.startsWith(command));
  });

  // 如果没有匹配的命令，返回空数组
  if (matchedCommands.length === 0) {
    return [];
  }

  // 如果有参数且匹配到命令，或者只匹配到一个命令，显示详细信息
  if ((params.length > 0 && matchedCommands.length > 0) || matchedCommands.length === 1) {
    return [{
      value: matchedCommands[0].command,
      command: matchedCommands[0].command + 
        (matchedCommands[0].aliases.length ? ` (${matchedCommands[0].aliases.join(', ')})` : ''),
      description: matchedCommands[0].description,
      usage: matchedCommands[0].usage,
      params: matchedCommands[0].params,
      examples: matchedCommands[0].examples,
      type: 'detail'
    }];
  }

  // 否则显示简略格式
  return matchedCommands.map(hint => ({
    value: hint.command,
    command: hint.command + (hint.aliases.length ? ` (${hint.aliases.join(', ')})` : ''),
    description: hint.description,
    type: 'brief'
  }));
} 