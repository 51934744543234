import { showMessage } from '../utils/message'
import mapManager from '../utils/mapManager'

export default function hideCommand(params) {
  const { currentElement,lastTarget } = mapManager.getElementControls();
  const { showInfoPanel,infoList,closeInfoWindow } = mapManager.getPanelControls();
  // const { infoList } = mapManager.getInputControls();

  // 处理 hide all 命令
  if (params.length === 1 && params[0].toLowerCase() === 'all') {
    // 清除所有元素
    for (const type in currentElement.value) {
      currentElement.value[type] = [];
    }
    // 关闭信息面板
    showInfoPanel.value = false;
    closeInfoWindow();
    showMessage('已清除所有地图元素', 'success');
    return;
  }

  // 原有的 hide 命令逻辑
  const lastTargetPids = new Set(lastTarget.value.map(item => item.pid));

  for (const type in currentElement.value) {
    currentElement.value[type] = currentElement.value[type].filter(element => !lastTargetPids.has(element.pid));
  }

  // 刷新 infoList
  if (infoList.value.type === 'categorized') {
    const updatedData = {};
    let totalElements = 0;

    Object.entries(infoList.value.data).forEach(([groupTag, group]) => {
      const updatedGroup = {};
      Object.entries(group).forEach(([subGroupTitle, subGroupItems]) => {
        const updatedSubGroup = subGroupItems.filter(item => !lastTargetPids.has(item.pid));
        if (updatedSubGroup.length > 0) {
          updatedGroup[subGroupTitle] = updatedSubGroup;
          totalElements += updatedSubGroup.length;
        }
      });
      if (Object.keys(updatedGroup).length > 0) {
        updatedData[groupTag] = updatedGroup;
      }
    });

    infoList.value = {
      ...infoList.value,
      data: updatedData
    };

    // 如果 infoList 为空，关闭 categorized-panel 并显示通知
    if (totalElements === 0) {
      showInfoPanel.value = false;
      showMessage('所有元素已隐藏，分类视图已关闭', 'info');
    }
  }

  closeInfoWindow();

  console.log('Updated currentElement:', JSON.parse(JSON.stringify(currentElement.value)));
  showMessage('已隐藏指定的地图元素', 'success');
}